// borrowed from praxis-ui

import {
  CompleteNotification,
  concat,
  defer,
  EMPTY,
  expand,
  ignoreElements,
  map,
  NextNotification,
  Observable,
  of,
  skip,
  timer,
} from "rxjs";

const DEFAULT_JITTER = 0.1;

/**
 * Backs off with time values
 * (firstMs) (secondMs) (firstMs + secondMs) (firstMs + secondMs + firstMs) ...
 */
export type FibonacciConfig = {
  type: "fibonacci";
  firstMs: number;
  secondMs: number;
};

export type BackoffConfig = {
  algo: FibonacciConfig;
  /**
   * The max number of milliseconds to wait
   */
  maxMs?: number;
  /**
   * If the current round lasts at least this number of milliseconds before
   * completing, then the timer will reset and the next run will happen on a
   * setTimeout of 0.
   */
  resetAfter?: number;
  /**
   * The actual delay will be set to `targetDelay * (1 + jitter *
   * Math.random())`. This is useful to prevent "thundering herds" and is easily
   * forgotten, so the field defaults to 0.1
   *
   * Note that jitter will only ever increase the delay and may make it exceed
   * `maxMs` (if that field is provided). This is necessary to prevent the
   * `maxMs` forcing the existence of a thundering herd.
   */
  jitter?: number;
};

/**
 * Reruns code on a backoff (TODO: better description)
 *
 * @param config The backoff configuration
 * @param code The code to run
 * @returns
 */
export function retryOnBackoff<T>(
  { algo, maxMs, jitter = DEFAULT_JITTER, resetAfter }: BackoffConfig,
  code: (iteration: number) => Observable<T>
): Observable<NextNotification<T> | CompleteNotification> {
  type Out = NextNotification<T> | CompleteNotification;

  const completed: Observable<Out> = of({ kind: "C" });

  let backoff = makeFibonacci(algo);
  let iteration = 0;
  let nextDelay = 0;

  return completed.pipe(
    expand<Out, Observable<Out>>((notification) => {
      // do nothing if it is a non-completion event
      if (notification.kind === "N") return EMPTY;

      let start = 0;

      // it was a completion, so we need to iterate
      return concat(
        // wait for the delay
        timer(nextDelay).pipe(ignoreElements()),

        // run the given code, allow errors to bleed out
        defer(() => {
          start = Date.now();
          return code(iteration++).pipe(
            map((value): NextNotification<T> => ({ kind: "N", value }))
          );
        }),

        // the code completed, so output that and set the next delay time
        defer(() => {
          const end = Date.now();

          // we always delay for a timeout event so that code does not have to
          // handle synchronous re-runs

          if (resetAfter != null && end > start + resetAfter) {
            // reset the backoff, set the delay to 0
            nextDelay = 0;
            backoff = makeFibonacci(algo);
          } else {
            nextDelay = backoff();
            if (maxMs != null && maxMs < nextDelay) {
              nextDelay = maxMs;
              backoff = () => maxMs;
            }
            nextDelay = Math.floor(nextDelay * (1 + jitter * Math.random()));
          }

          return completed;
        })
      );
    }),

    // to start the loop, we had to send in an initial complete event, which we
    // do not want escaping
    skip(1)
  );
}

/////////////////////////////////////////////////////////////////////////////
// utils
/////////////////////////////////////////////////////////////////////////////

function makeFibonacci({ firstMs, secondMs }: FibonacciConfig): () => number {
  let nextDelay = firstMs;
  let secondNextDelay = secondMs;

  return () => {
    const delay = nextDelay;
    nextDelay = secondNextDelay;
    secondNextDelay += delay;
    return delay;
  };
}
