/**
 * This is a linked list object that points up the tree. It's reference uniquely
 * identifies a widget.
 */
export class PathNode {
  readonly parent: PathNode | null;
  readonly key: string;

  constructor(key: string, parent?: PathNode) {
    this.parent = parent || null;
    this.key = key;
  }

  child(key: string): PathNode {
    return new PathNode(key, this);
  }

  toPathArray(): string[] {
    const { parent, key } = this;
    if (parent) {
      const path = parent.toPathArray();
      path.push(key);
      return path;
    } else {
      return [key];
    }
  }
}
