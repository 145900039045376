import React, {
  ComponentType,
  createContext,
  createElement,
  Fragment,
  memo,
  useContext,
} from "react";
import { Flex, Grid, Row } from "./Row";
import { Updating, useUpdatableProps } from "./useUpdatableProps";
import { Widget } from "./widgets/Widget";
import { Construct, Code } from "micromark-util-types";
import { fromMarkdown, Options as MdOptions } from "mdast-util-from-markdown";
import { toHast, Options as HtmlOptions } from "mdast-util-to-hast";
import { factorySpace } from "micromark-factory-space";
import { markdownLineEnding } from "micromark-util-character";
import { codes } from "micromark-util-symbol/codes";
import { types } from "micromark-util-symbol/types";
import { SyntaxHighlight } from "./widgets/CodeWidget";
import { Node as MdastNode } from "unist";
import { cx, renderValue } from "./widgets/renderValue";

// this is the recommended way to extend the micromark types
declare module "micromark-util-types" {
  interface TokenTypeMap {
    [WidgetToken.Node]: WidgetNode;
    [WidgetToken.Label]: undefined | string;
    [WidgetToken.Id]: undefined | string;
    [WidgetsToken.Node]: WidgetsNode;
    [WidgetsToken.Grid]: WidgetsGridNode;
    [WidgetsToken.Spring]: SpringNode;
    [ArgToken.Node]: ArgNode;
  }
}

declare module "mdast-util-from-markdown" {
  interface CompileData {
    [WidgetToken.Label]: string;
  }
}

/////////////////////////////////////////////////////////////////////////////
// Types
/////////////////////////////////////////////////////////////////////////////

enum WidgetToken {
  Node = "widget",
  Label = "widgetLabel",
  Id = "widgetId",
}

type WidgetNode = {
  type: WidgetToken.Node;
  widgetId: string;
  label: undefined | string;
};

enum WidgetsToken {
  Node = "widgets",
  Grid = "widgetsGrid",
  Spring = "widgetsSpring",
}

interface SpringNode extends MdastNode {
  type: WidgetsToken.Spring;
}

interface WidgetsNode extends MdastNode {
  type: WidgetsToken.Node;
  children: (SpringNode | WidgetNode)[];
}

interface WidgetsGridNode extends MdastNode {
  type: WidgetsToken.Grid;
  children: WidgetsNode[];
}

enum CustomNode {
  React = "react",
}

type ReactNode = {
  type: CustomNode.React;
  el: JSX.Element;
};

enum ArgToken {
  Node = "arg",
}

type ArgNode = {
  type: ArgToken.Node;
  index: number;
};

type HtmlAstNode =
  | ReturnType<typeof toHast>
  | WidgetsNode
  | WidgetNode
  | WidgetsGridNode
  | ArgNode
  | ReactNode;

type ParseData = {
  uniqUnparsedId: string;
  Comp: ComponentType<{}>;
  references: Set<string>;
};

export const MarkdownArgs = createContext<ReadonlyArray<Updating<string>>>([]);

export enum WidgetGroupEl {
  Widget,
  Spring,
  FixedGap,
  VerticalSeparator,
}

export type GroupAlign = "default" | "left" | "center" | "right";
export type WidgetData = {
  type: WidgetGroupEl.Widget;
  data: {
    widgetId: string;
    mdLabel: undefined | string;
    align: GroupAlign;
  };
};

export type WidgetGroupData =
  | WidgetData
  | {
      type: WidgetGroupEl.Spring;
    };

export type WidgetFlexData =
  | {
      type: WidgetGroupEl.Widget;
      data: {
        widgetId: string;
        mdLabel: undefined | string;
      };
    }
  | {
      type: WidgetGroupEl.Spring;
    }
  | {
      type: WidgetGroupEl.FixedGap;
    }
  | {
      type: WidgetGroupEl.VerticalSeparator;
    };

export const SPRING: WidgetGroupData = { type: WidgetGroupEl.Spring };
export const FIXED_GAP: WidgetFlexData = { type: WidgetGroupEl.FixedGap };
export const VERTICAL_SEP: WidgetFlexData = {
  type: WidgetGroupEl.VerticalSeparator,
};

/////////////////////////////////////////////////////////////////////////////
// Parsing
/////////////////////////////////////////////////////////////////////////////

let nextUniqUnparsedId = 1;
const cachedParses = new Map<string, ParseData>();

function isWidgetsNode(node: MdastNode | undefined): node is WidgetsNode {
  return node !== undefined && node.type === WidgetsToken.Node;
}

export function parseMd(source: string): ParseData {
  // skip doing any work if we have already saved the results
  const preparsed = cachedParses.get(source);
  if (preparsed) return preparsed;

  const [el, references] = processor()(source);

  const parsed: ParseData = {
    uniqUnparsedId: `u${nextUniqUnparsedId++}`,
    Comp: memo(
      () => el,
      () => true // never needs to re-render
    ),
    references,
  };

  cachedParses.set(source, parsed);

  return parsed;
}

/////////////////////////////////////////////////////////////////////////////
// Utils
/////////////////////////////////////////////////////////////////////////////

let _processor: null | ((doc: string) => [JSX.Element, Set<string>]) = null;
function processor(): NonNullable<typeof _processor> {
  if (_processor) return _processor;

  const widgetConstruct: Construct = {
    name: WidgetToken.Node,
    concrete: true,
    tokenize(effects, ok, nok) {
      return start;

      function start(cp: Code) {
        effects.enter(WidgetToken.Node);

        if (cp === codes.leftSquareBracket) {
          return startLabel;
        } else {
          // cp === codes.leftCurlyBrace
          return startMarker(cp);
        }
      }

      function startLabel(cp: Code) {
        effects.enter(WidgetToken.Label);
        effects.consume(cp);
        effects.enter(types.chunkString, { contentType: "string" });
        return insideLabel;
      }

      function insideLabel(cp: Code) {
        if (cp === codes.eof || markdownLineEnding(cp)) {
          return nok(cp);
        }

        if (cp === codes.rightSquareBracket) {
          effects.exit(types.chunkString);
          effects.consume(cp);
          effects.exit(WidgetToken.Label);
          return startMarker;
        }

        effects.consume(cp);
        return insideLabel;
      }

      function startMarker(cp: Code) {
        if (cp !== codes.leftCurlyBrace) {
          return nok(cp);
        }

        effects.enter(WidgetToken.Id);
        effects.consume(cp);
        effects.enter(types.chunkString, { contentType: "string" });
        return firstCp;
      }

      function firstCp(cp: Code) {
        return cp === codes.rightCurlyBrace ? nok(cp) : inside(cp);
      }

      function inside(cp: Code) {
        if (cp === codes.eof || markdownLineEnding(cp)) {
          return nok(cp);
        }

        if (cp === codes.rightCurlyBrace) {
          effects.exit(types.chunkString);
          effects.consume(cp);
          effects.exit(WidgetToken.Id);
          effects.exit(WidgetToken.Node);
          return ok;
        }

        effects.consume(cp);
        return inside;
      }
    },
  };

  const widgetsConstruct: Construct = {
    name: WidgetsToken.Node,
    concrete: true,
    tokenize(effects, ok, nok) {
      let elements = 0;

      return start;

      function start(cp: Code) {
        elements = 0;
        effects.enter(WidgetsToken.Node);
        return topLevel(cp);
      }

      function postItem(cp: Code) {
        elements++;

        if (cp !== codes.space) {
          return finish(cp);
        }

        return factorySpace(effects, topLevel, types.whitespace)(cp);
      }

      function topLevel(cp: Code) {
        if (cp === codes.leftSquareBracket || cp === codes.leftCurlyBrace) {
          return effects.attempt(widgetConstruct, postItem, finish)(cp);
        } else if (cp === codes.tilde) {
          effects.enter(WidgetsToken.Spring);
          effects.consume(cp);
          effects.exit(WidgetsToken.Spring);
          return postItem;
        }

        return finish(cp);
      }

      function finish(cp: Code) {
        // no widgets
        if (!elements) return nok(cp);

        effects.exit(WidgetsToken.Node);
        return ok(cp);
      }
    },
  };

  const argConstruct: Construct = {
    name: ArgToken.Node,
    concrete: true,
    tokenize(effects, ok, nok) {
      return start;

      function start(cp: Code) {
        effects.enter(ArgToken.Node);
        effects.consume(cp); // always a dollar-sign
        return startCurly;
      }

      function startCurly(cp: Code) {
        if (cp !== codes.leftCurlyBrace) return nok(cp);

        effects.consume(cp);
        effects.enter(types.chunkString, { contentType: "string" });
        return firstCp;
      }

      function firstCp(cp: Code) {
        return cp === codes.rightCurlyBrace ? nok(cp) : inside(cp);
      }

      function inside(cp: Code) {
        if (codes.digit0 <= cp! && cp! <= codes.digit9) {
          effects.consume(cp);
          return inside;
        }

        if (cp === codes.rightCurlyBrace) {
          effects.exit(types.chunkString);
          effects.consume(cp);
          effects.exit(ArgToken.Node);
          return ok;
        }

        return nok(cp);
      }
    },
  };

  const mdOptions: MdOptions = {
    extensions: [
      {
        // flow means they are not contained within paragraphs
        flow: {
          [codes.leftSquareBracket]: widgetsConstruct,
          [codes.leftCurlyBrace]: widgetsConstruct,
          [codes.tilde]: widgetsConstruct,
        },
        // args can be put anywhere text is valid
        text: {
          [codes.dollarSign]: argConstruct,
        },
      },
    ],
    mdastExtensions: [
      {
        enter: {
          [WidgetsToken.Node](token) {
            const node: WidgetsNode = {
              type: WidgetsToken.Node,
              children: [],
            };

            this.enter(node as any, token);
          },
          [WidgetsToken.Spring](token) {
            const node: SpringNode = {
              type: WidgetsToken.Spring,
            };

            this.enter(node as any, token);
            this.exit(token);
          },
          [WidgetToken.Node](token) {
            const node: WidgetNode = {
              type: WidgetToken.Node,
              widgetId: "",
              label: undefined,
            };

            this.enter(node as any, token);
            this.buffer();
          },
          [WidgetToken.Label]() {
            this.buffer();
          },
          [ArgToken.Node](token) {
            const node: ArgNode = {
              type: ArgToken.Node,
              index: -1,
            };

            this.enter(node as any, token);
            this.buffer();
          },
        },
        exit: {
          [WidgetsToken.Node](token) {
            this.exit(token);
          },
          [WidgetToken.Label]() {
            const label = this.resume();
            this.setData(WidgetToken.Label, label);
          },
          [WidgetToken.Node](token) {
            const widgetId = this.resume();
            const label: undefined | string = this.getData(
              WidgetToken.Label
            ) as any;
            const node = this.exit(token) as unknown as WidgetNode;

            node.widgetId = widgetId;
            if (label != null) {
              node.label = label;
              this.setData(WidgetToken.Label);
            }
          },
          [ArgToken.Node](token) {
            const indexStr = this.resume();
            const node = this.exit(token) as unknown as ArgNode;
            node.index = parseInt(indexStr, 10);
          },
        },
      },
    ],
  };

  const htmlOptions: HtmlOptions = {
    passThrough: [
      WidgetsToken.Node,
      WidgetsToken.Grid,
      WidgetsToken.Spring,
      WidgetToken.Node,
      ArgToken.Node,
      CustomNode.React,
    ],
    handlers: {
      code: (h, node) => h(node.position, "fenced", node),
    },
  };

  // const widgetHtml = {
  //   enter: {
  //     widget() {
  //       this.buffer();
  //     },
  //   },
  //   exit: {
  //     widget() {
  //       const id = this.resume();
  //       this.raw(JSON.stringify(id));
  //     },
  //   },
  // };

  _processor = (doc) => {
    // console.log("start", doc);

    // parse the markdown
    const mdAst = fromMarkdown(stripIndent(doc), mdOptions);
    const references = new Set<string>();

    const children = mdAst.children as MdastNode[];

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (isWidgetsNode(child)) {
        // we have a single widget row
        if (child.children.length === 1) {
          children.splice(i, 1, child.children[0]);
          continue;
        }
        const widgetsGrid: WidgetsGridNode = {
          type: WidgetsToken.Grid,
          children: [child],
        };
        let j = i + 1;
        let nextChild = children[j];
        while (
          isWidgetsNode(nextChild) &&
          nextChild.children.length == child.children.length
        ) {
          widgetsGrid.children.push(nextChild);
          nextChild = children[++j];
        }
        // we got more than one widget row
        if (j > i + 1) {
          children.splice(i, j - i, widgetsGrid as any);
        }
      }
    }

    // translate the markdown to html tree
    const htmlAst = toHast(mdAst, htmlOptions);

    const react = htmlAstToReact(references, htmlAst);
    return [react, references];
  };

  return _processor;
}

function MarkdownArg(props: { index: number }) {
  const args = useContext(MarkdownArgs);
  const { arg } = useUpdatableProps({ arg: args[props.index] });
  return <bdi aria-busy={arg.pending}>{renderValue(arg.val)}</bdi>;
}

function idAndAlign(widgetId: string) {
  let leftMarker = false;
  let rightMarker = false;
  let align: GroupAlign = "default";
  if (widgetId.startsWith(":")) {
    widgetId = widgetId.slice(1);
    leftMarker = true;
  }
  if (widgetId.endsWith(":")) {
    widgetId = widgetId.slice(0, -1);
    rightMarker = true;
  }
  if (leftMarker) {
    if (rightMarker) {
      align = "center";
    } else {
      align = "right";
    }
  } else {
    if (rightMarker) {
      align = "left";
    }
  }
  return { widgetId, align };
}

function htmlAstToReact(references: Set<string>, root: HtmlAstNode) {
  // spec: https://github.com/syntax-tree/hast

  function nodesToGroupData(nodes: Array<SpringNode | WidgetNode>) {
    return nodes.map((child): WidgetGroupData => {
      if (child.type === WidgetsToken.Spring) {
        return SPRING;
      } else {
        const { widgetId: widgetIdWithMarkers, label } = child;
        const { widgetId, align } = idAndAlign(widgetIdWithMarkers);

        // split widgetId into bits and add references
        widgetId
          .split(",")
          .map((x) => x.trim())
          .filter(isWidgetId)
          .forEach((wid) => references.add(wid));

        return {
          type: WidgetGroupEl.Widget,
          data: {
            widgetId,
            mdLabel: label,
            align,
          },
        };
      }
    });
  }

  const recurse = (node: HtmlAstNode) => {
    switch (node?.type) {
      case "root": {
        const children = node.children.map(recurse).filter(Boolean);
        return children.length > 1
          ? createElement(Fragment, undefined, ...children)
          : children.length
          ? children[0]
          : null;
      }
      case "element": {
        const { tagName, properties, children } = node;

        if (tagName === "fenced") {
          return (
            <div className="show-lines">
              <SyntaxHighlight
                code={properties?.value}
                language={properties?.lang}
              />
            </div>
          );
        }

        // do we render templates?
        // if (node.tagName === "template") {
        //   return htmlAstToReact(node.content);
        // }

        // TODO: https://github.com/syntax-tree/hast#properties
        // the properties may need conversion for react

        return createElement(tagName, properties, ...children.map(recurse));
      }
      case "doctype":
      case "comment":
        return null;
      case "text":
        return node.value;
      case WidgetToken.Node:
        const { widgetId: widgetIdWithMarkers, label } = node;
        const { widgetId, align } = idAndAlign(widgetIdWithMarkers);
        const bits = widgetId.split(",").map((x) => x.trim());

        // regular widgetId
        if (bits.length === 1) {
          if (references.has(widgetId)) {
            console.error("Multiple widgetId", widgetId);
            return null;
          }

          references.add(widgetId);
          const theWidget = (
            <Widget key={widgetId} id={widgetId} label={label} />
          );
          if (align === "default") {
            return theWidget;
          } else {
            return (
              <div
                className={cx(
                  "widget-align-container",
                  `widget-align-${align}`
                )}
              >
                {theWidget}
              </div>
            );
          }
        } else {
          const data = bits.map((bit): WidgetFlexData => {
            if (bit === "-") {
              return FIXED_GAP;
            } else if (bit === "~") {
              return SPRING;
            } else if (bit === "|") {
              return VERTICAL_SEP;
            } else {
              references.add(bit);

              return {
                type: WidgetGroupEl.Widget,
                data: {
                  widgetId: bit,
                  mdLabel: undefined,
                },
              };
            }
          });

          return <Flex data={data} align={align} />;
        }
      case WidgetsToken.Node: {
        const { children } = node;
        const data = nodesToGroupData(children);
        return <Row data={data} />;
      }
      case WidgetsToken.Grid: {
        const { children } = node;
        const rows = children.map((row) => nodesToGroupData(row.children));
        return <Grid rows={rows} />;
      }
      case ArgToken.Node:
        return <MarkdownArg index={node.index} />;
      case CustomNode.React:
        return node.el;
      default:
        console.error(`unrecognized ast type: ${node?.type}`);
        return null;
    }
  };

  return recurse(root);
}

export function isWidgetId(wid: string) {
  switch (wid) {
    case "-":
    case "~":
    case "|":
      return false;
  }
  return true;
}

// strip indent from markdown
function stripIndent(txt: string = ""): string {
  const match = txt.toString().match(/^[^\S\n]*(?=\S)/gm);
  if (!match) return txt;

  const lengths = match.map((el) => el.length);

  // if every element except the first one has an indent it's the:
  // ui.md`foo
  //   bar
  //   xyz`;
  // then ignore the first line's indent (which is zero)
  if (lengths.length > 1 && lengths.every((l, i) => (i ? !!l : true))) {
    lengths.shift();
  }

  const indent = Math.min(...lengths);
  if (indent) {
    const regexp = new RegExp(`^[ ]{${indent}}`, "gm");
    return txt.replace(regexp, "");
  }
  return txt;
}
