export const SKYMASS_SUBDOMAIN = "skymass";

export const SKYMASS_HOSTS = ["skymass.dev", "beta.skymass.dev", "127.0.0.1"];

interface SubdomainAndPath {
  is_skymass: boolean;
  subdomain: string;
  path: string;
  route: string;
  custom_domain?: string;
}

export function getSubdomainAndPath(url: string): SubdomainAndPath {
  // @ts-ignore
  const loc = new URL(url);
  const host = loc.hostname;
  const pathname = loc.pathname;

  const pathParts = pathname.split("/").filter((x) => x !== "");

  if (SKYMASS_HOSTS.includes(host)) {
    // /app/<subdomain>/<path>
    const [, subdomain, path = "", ...routeParts] = pathParts;
    return {
      is_skymass: subdomain === SKYMASS_SUBDOMAIN,
      subdomain,
      path: `/${path}`,
      route: `/${routeParts.join("/")}`,
    };
  } else if (host.endsWith("skymass.dev")) {
    // <foo>.skymass.dev/<path>/
    const subdomain = host.split(".")[0];
    const [path = "", ...routeParts] = pathParts;
    return {
      is_skymass: false,
      subdomain,
      path: `/${path}`,
      route: `/${routeParts.join("/")}`,
    };
  }

  // completely custom domain
  // acme.com/<app> ->
  const [path = "", ...routeParts] = pathParts;
  return {
    is_skymass: false,
    subdomain: host,
    path: `/${path}`,
    route: `/${routeParts.join("/")}`,
    custom_domain: host,
  };
}

/*
There are three types of SkyMass URLS

Reserved: beta, 

If (skymass.dev || beta.skymass.dev || 127.0.0.1) {
   skymass.dev/app/subdomain/app
} else {
  subdomain.skymass.dev/app
  acme.com/app (acme.com is mapped to domain)
}
*/

export function urlForPath(path: string) {
  if (SKYMASS_HOSTS.includes(location.hostname)) {
    // skymass.dev/app/<subdomain>/* -> /app/<subdomain>/path
    const [x_app, subdomain] = location.pathname
      .split("/")
      .filter((x) => x !== "");

    return ["", "app", subdomain].join("/") + path;
  }

  // acme.com -> acme.com/<path>
  // <foo>.skymass.dev:1234/ -> <foo>.skymass.dev:1234/<path>
  return path;
}
