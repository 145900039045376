import {
  DeclaredToast,
  P2CCmdMsg,
  P2CMsg,
  RegionType,
  RootRegionId,
} from "./protocol";
import { UpdatableInstance } from "./useUpdatableProps";
import produce, { enableMapSet, Draft } from "immer";
import { RegionState, RegionStructure } from "./RegionState";
import { unstable_batchedUpdates } from "react-dom";
import { ConsumerManager } from "./ConsumerManager";
import { handleHistory } from "./widgets/History";

export type SendUpdate = (
  regionId: string,
  path: ReadonlyArray<string>,
  key: string,
  val: any,
  isValid: boolean,
  debounce: number
) => number;

type AppletStructure = Readonly<{
  regions: ReadonlyMap<string, RegionState>;
  rootRegionId: string;
  modalIds: ReadonlyArray<string>;
  alertIds: ReadonlyArray<string>;
}>;

/**
 * This class
 */
export class AppletState {
  readonly structure = new UpdatableInstance<AppletStructure>({
    pending: true,
    val: {
      regions: new Map(),
      rootRegionId: "",
      alertIds: [],
      modalIds: [],
    },
  });

  readonly regions = new Map<string, RegionState>();
  readonly latestToast = new UpdatableInstance<DeclaredToast>();
  private readonly manager: ConsumerManager;

  constructor(manager: ConsumerManager) {
    this.manager = manager;
    enableMapSet();
  }

  onServerUpdate(updates: P2CMsg[]) {
    const start = Date.now();
    unstable_batchedUpdates(() => {
      const current = this.structure.content.val;
      const updated = produce(current, (state) => {
        state &&
          updates.forEach((update) => {
            this.applyUpdateMsg(state, update);
          });
      });

      if (!updated) return;

      // regions may have built up some changes
      updated.regions.forEach((region) => {
        region.commitDraft();
      });

      updates.forEach((update) => {
        switch (update.type) {
          case "toast":
            this.latestToast.updateVal(update.data);
            break;
          case "history":
            handleHistory(update.data);
            break;
        }
      });

      if (updated !== current) {
        this.structure.updateVal(updated);
      }
    });

    console.log(`onServerUpdate took ${Date.now() - start}ms`);
  }

  private applyUpdateMsg(state: Draft<AppletStructure>, msg: P2CMsg) {
    switch (msg.type) {
      case "log":
      case "subject":
      case "history":
      case "toast":
        // handled elsewhere
        break;
      case "created": {
        const {
          regionId,
          data: { type: regionType, parentId },
        } = msg;

        switch (regionType) {
          case RegionType.Alert:
            state.alertIds.push(regionId);
            break;
          case RegionType.Modal:
            state.modalIds.push(regionId);
            break;
          case RegionType.Launched:
          case RegionType.Cover:
            if (parentId) {
              // parent should be defined except for the root, the ?. check is just
              // for proper typings
              state.regions
                .get(parentId === RootRegionId ? state.rootRegionId : parentId)
                ?.addChild(msg);
            } else {
              state.rootRegionId = regionId;
            }
            break;
        }

        // @ts-expect-error mutable object in an immutable map
        state.regions.set(regionId, new RegionState(this.manager, msg));
        break;
      }
      case "removed": {
        const { regionId } = msg;
        const region = state.regions.get(regionId);
        if (!region) {
          console.error(`unrecognized removed region ${regionId}`);
          return;
        }

        state.regions.delete(regionId);

        switch (region.type) {
          case RegionType.Alert: {
            const alertIndex = state.alertIds.indexOf(regionId);
            if (alertIndex !== -1) {
              state.alertIds.splice(alertIndex, 1);
            }
            break;
          }
          case RegionType.Modal: {
            const modalIndex = state.modalIds.indexOf(regionId);
            if (modalIndex !== -1) {
              state.modalIds.splice(modalIndex, 1);
            }
            break;
          }
          case RegionType.Launched:
          case RegionType.Cover: {
            const parentId = region.parentId;
            parentId && state.regions.get(parentId)?.removeChild(regionId);
            break;
          }
        }
        break;
      }
      case "nochange":
      case "updating":
      case "updated":
      case "set": {
        const region = state.regions.get(msg.regionId);
        if (!region) {
          console.error(`Unknown region nochange ${msg.regionId}`);
          break;
        }

        region.handleServerUpdate(msg);
        break;
      }
      case "method": {
        const region = state.regions.get(msg.regionId);
        if (!region) {
          console.error(
            `Unknown region while looking to call method ${msg.regionId}`
          );
          break;
        }

        region.callMethod(msg);
        break;
      }
      case "cmd":
        runCmd(msg);
        break;
      default:
        // @ts-expect-error
        console.error(`Unrecognized msg type ${msg.type}`);
    }
  }
}

function runCmd(cmd: P2CCmdMsg) {
  switch (cmd.command) {
    case "pushState": {
      // do something
      break;
    }
    default: {
      console.error(`Unrecognized cmd ${cmd.command}`);
    }
  }
}
