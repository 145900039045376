import {
  AdvancedSerializeTypes,
  C2PTranscript,
  C2PUpdate,
  C2SMsg,
  InitialPageParams,
  INVALID_VALUE,
  LogLevel,
  P2CMsg,
  Path,
  RSeq,
  S2CConnectError,
  S2CMsg,
  S2CProducerDied,
} from "./protocol";
import { BackoffConfig } from "./shared/backoff";
import { socketRx, TypedSocket } from "./shared/socket";
import { deserializer, serializer } from "@grinstead/atomize";
import { Subscription, Observable, Subject } from "rxjs";
import { UpdatableInstance } from "./useUpdatableProps";
import { together } from "./shared/rx_utils";
import { devHub, devLog } from "./DevHub";
import { AppletState } from "./AppletState";
import { debounceMinTime } from "./debounceMinTime";
import { KEY_DELETED, VALUE_UNCHANGED } from "./shared/patch";

function uuidv4() {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

let blockRefreshUntil:
  | undefined
  | {
      time: number;
      delay: number;
    };

type CM = {
  host: string;
  subdomain: string;
  page: string;
  params: InitialPageParams;
  onConnectError: (error: S2CConnectError | S2CProducerDied) => void;
};

const backoffConfig: BackoffConfig = {
  algo: {
    type: "fibonacci",
    firstMs: 1000,
    secondMs: 1000,
  },
  maxMs: 3000, // low for testing
  resetAfter: 10000,
};

export const serializeData: (arg: any) => ArrayBuffer = serializer({
  instance: (val: any, write: (arg: unknown) => void) => {
    if (val instanceof Date) {
      write(AdvancedSerializeTypes.Date);
      write(val.getTime());
    } else if (val === INVALID_VALUE) {
      write(AdvancedSerializeTypes.InvalidValue);
    } else {
      console.error(`cannot serialize!`, val);
      write(AdvancedSerializeTypes.UNRECOGNIZED);
    }
  },
});

function maybe_session_cookies() {
  if (window.parent !== window) {
    const session = localStorage.getItem("session");
    const sig = localStorage.getItem("session.sig");
    if (session && sig) {
      return { session, sig };
    }
  }
  return null;
}

export class ConsumerManager {
  /**
   * The consumer sequence number (named after the concept of "sequence numbers"
   * in tcp) increments up every time we send an update. We use this to tell if
   * the server has handled our update
   *
   * We increment it every time we send, so what gets sent to the client will
   * always be positive.
   */
  private cSeq = 0;

  private socketSub: Subscription;
  private socket: null | TypedSocket<C2SMsg>;
  readonly socketRx: Observable<P2CMsg[]>;

  private sendSub: null | Subscription = null;
  private outgoingUpdatesSubject = new Subject<[number, [string, C2PUpdate]]>();

  readonly host: string;
  readonly subdomain: string;
  readonly page: string;

  readonly app = new AppletState(this);

  private readonly onConnectError: (
    error: S2CConnectError | S2CProducerDied
  ) => void;

  private readonly subjects = new Map<string, UpdatableInstance<any>>();

  private hasEstablished: boolean = false;
  private warnOnNoProducer: boolean = true;

  private msgCounter: number = 0;
  private readonly uaUUID: string = uuidv4();

  constructor({ host, subdomain, page, params, onConnectError }: CM) {
    this.host = host;
    this.subdomain = subdomain;
    this.page = page;
    this.onConnectError = onConnectError;

    const isNotTls =
      host.startsWith("localhost:") || host.startsWith("127.0.0.1:");

    const origin = isNotTls ? `ws://${host}` : `wss://${host}`;

    this.socketSub = socketRx<S2CMsg, C2SMsg>(
      backoffConfig,
      `${origin}/consumer`
    ).subscribe((event) => {
      switch (event.type) {
        case "open": {
          const { data } = event;
          console.log(`Opened socket ${data.iteration}`);
          this.socket = data.socket;
          data.socket.sendJson({
            type: "connect",
            cookies: maybe_session_cookies(),
            subdomain,
            path: page,
            params,
            msgCounter: this.msgCounter,
            uaUUID: this.uaUUID,
          });
          break;
        }
        case "msg": {
          const msg = event.data;
          if (msg instanceof ArrayBuffer) {
            // TODO: should we clear connect error?
            this.hasEstablished = true;
            this.warnOnNoProducer = true;
            this.msgCounter++;

            const parsed: P2CMsg[] = this.deserialize(new Uint8Array(msg));
            console.log(`UPDATE (${msg.byteLength} bytes)`, parsed);

            // handle subject notifications
            parsed.forEach((update) => {
              switch (update.type) {
                case "subject": {
                  const { slotId, data: notification } = update;
                  if (notification.kind === "P") {
                    const updatable = this.subjects.get(slotId);
                    updatable?.markPending();
                  } else if (notification.kind === "N") {
                    const updatable = this.subjects.get(slotId);
                    updatable?.updateVal(notification.value);
                  } else {
                    this.subjects.delete(slotId);
                  }
                  break;
                }
                case "log": {
                  devHub().appendLogLine(update.data);
                  break;
                }
                default:
                  // the rest is handled in Applet
                  break;
              }
            });

            this.app.onServerUpdate(parsed);
          } else if (msg.type === "debug_refresh") {
            const path = msg.path;
            if (path) {
              // TODO: notify the user?
              window.location.replace(path);
            } else {
              if (blockRefreshUntil && Date.now() < blockRefreshUntil.time) {
                // if the clock gets reset really weirdly, we can at least cap
                // the refresh time
                const delay = Math.min(
                  blockRefreshUntil.delay,
                  Date.now() - blockRefreshUntil.time
                );

                setTimeout(() => {
                  window.location.reload();
                }, delay);
              } else {
                window.location.reload();
              }
            }
          } else if (msg.type === "producer_died") {
            this.onConnectError(msg);
            if (this.warnOnNoProducer) {
              this.warnOnNoProducer = false;
              devLog("No backend currently running", LogLevel.Warn);
            }
          } else if (msg.type === "no_producer") {
            this.disconnect();
            this.onConnectError(msg);
            if (this.warnOnNoProducer) {
              this.warnOnNoProducer = false;
              devLog("No backend currently running", LogLevel.Warn);
            }
          } else if (msg.type === "no_subdomain") {
            this.disconnect();
            this.onConnectError(msg);
            if (this.warnOnNoProducer) {
              this.warnOnNoProducer = false;
              devLog("No backend currently running", LogLevel.Warn);
            }
          } else if (msg.type === "auth_error") {
            console.log(`Connection error`, msg);
            this.disconnect();
            this.onConnectError(msg);
          } else {
            msg satisfies never;
            console.log("TODO", msg);
          }
          break;
        }
        case "close": {
          this.socket = null;
          if (this.hasEstablished) {
            // window.location.reload();
          }
          break;
        }
      }
    });
  }

  readonly deserialize: (bytes: Uint8Array) => any = deserializer(
    (next: () => any) => {
      const type: AdvancedSerializeTypes = next();
      switch (type) {
        case AdvancedSerializeTypes.UNRECOGNIZED:
          return undefined;
        case AdvancedSerializeTypes.Subject: {
          const slotId: string = next();
          let updatable = this.subjects.get(slotId);
          if (!updatable) {
            updatable = new UpdatableInstance();
            this.subjects.set(slotId, updatable);
          }

          return updatable;
        }
        case AdvancedSerializeTypes.Date: {
          const time: number = next();
          return new Date(time);
        }
        case AdvancedSerializeTypes.InvalidValue:
          return INVALID_VALUE;
        case AdvancedSerializeTypes.ValueUnchanged:
          return VALUE_UNCHANGED;
        case AdvancedSerializeTypes.KeyDeleted:
          return KEY_DELETED;
        default: {
          console.error(`Unrecognized serialization ${type}`);
        }
      }
    }
  );

  disconnect() {
    this.socketSub.unsubscribe();
  }

  runUpdate(
    regionId: string,
    rSeq: RSeq,
    path: Path,
    key: string,
    val: Uint8Array,
    isValid: boolean,
    debounce: number
  ) {
    if (!this.sendSub) {
      this.sendSub = this.outgoingUpdatesSubject
        .pipe(
          together(debounceMinTime(({ batch }) => batch[batch.length - 1][0]))
        )
        .subscribe((updates) => {
          let prior = updates[0][1];
          const cleanedUpdates = [prior];
          updates.forEach(([_debounce, update], i) => {
            if (i === 0) return;

            const [priorRegion, priorUpdate] = prior;
            const [region, currUpdate] = update;
            cleanedUpdates.push(update);

            // TODO: increment cSeq per update and send them as one transcript per update
            /*
            if (
              priorRegion === region &&
              currUpdate.key === priorUpdate.key &&
              priorUpdate.path.length === currUpdate.path.length &&
              priorUpdate.path.every((k, i) => k === currUpdate.path[i])
            ) {
              // swap out the last update as they change the same field
              cleanedUpdates[cleanedUpdates.length - 1] = update;
            } else {
              cleanedUpdates.push(update);
            }
            */

            prior = update;
          });

          if (!this.socket) {
            console.error(`TODO: runUpdate called while no socket`);
            return;
          }

          const transcript: C2PTranscript = {
            type: "transcript",
            cSeq: this.cSeq - cleanedUpdates.length + 1,
            updates: cleanedUpdates,
          };

          this.socket.ws.send(serializeData(transcript));
        });
    }

    this.outgoingUpdatesSubject.next([
      debounce,
      [regionId, { path, key, val, isValid, rSeq }],
    ]);
    return ++this.cSeq;
  }
}

/**
 * This will prevent the system from immediately invoking a debug_refresh.
 * Useful for guaranteeing a UI element will appear for enough time.
 * @param delay The delay in milleseconds to wait for refreshing
 */
export function postponeRefreshes(delay: number) {
  const time = Date.now() + delay;
  if (blockRefreshUntil && blockRefreshUntil.time > time) return;

  blockRefreshUntil = { time, delay };
}
