import React from "react";
import { RowProps } from "../Row";
import { useAction } from "../server_hooks";
import { ZW_SPACE } from "./renderValue";
import { Icon } from "./Icon";
import { requestLogin, useUser } from "../Header";
import { getSubdomainAndPath, urlForPath } from "../paths";
import {
  useSkyMassPopupMenu,
  MenuItems,
  AnyMenuItem,
} from "./PopupMenuHandler";
import { inverse } from "./Theme";

interface BaseItem {
  label?: string;
  icon?: string;
}

interface ActionItem extends BaseItem {
  disabled?: boolean;
  action?: string;
}

interface Heading extends BaseItem {
  heading: true;
}

type Separator = "---";

type MenuItem = ActionItem | Separator | Heading;

interface Menu extends BaseItem {
  items: MenuItem[];
}

interface MenuButton extends ActionItem {
  appearance?:
    | "button"
    | "button_outline"
    | "button_secondary"
    | "button_muted"
    | "link";
}

type MenuBar = (Menu | MenuButton)[];

type Logo = { src?: string; dark?: string; text: string; action?: string };

type MenuBarProps = {
  items?: MenuBar;
  logo?: Logo;
  appearance?: "inverse";
} & RowProps;

type OnClickEventTrigger = (val: string, isBlocking?: boolean) => void;

const MENU_SEPARATOR: Separator = "---";

export function MenuBar({ items, logo, appearance }: MenuBarProps) {
  const clickAction = useAction<string>("click");
  const onAction = (action: string) => clickAction(action, action);

  const user = useUser();

  const userName = user
    ? [
        { label: user, onClick: () => undefined, disabled: true },
        MENU_SEPARATOR,
      ]
    : [];

  const userItems: MenuItems = [
    ...userName,
    {
      label: "App Directory",
      onClick: () => location.assign(urlForPath("")),
    },
    MENU_SEPARATOR,
    user
      ? { label: "Logout", onClick: () => location.assign("/auth/logout") }
      : { label: "Login", onClick: requestLogin },
  ];

  if (getSubdomainAndPath(location.href).is_skymass) {
    userItems.push(
      MENU_SEPARATOR,
      { label: "Home", onClick: () => location.assign("/") },
      {
        label: "Dev Settings",
        onClick: () => location.assign("/app/skymass/admin"),
      },
      { label: "Docs", onClick: () => location.assign("/app/skymass/docs") },
      {
        label: "Components",
        onClick: () => location.assign("/app/skymass/components"),
      }
    );
  }

  const userMenu = (
    <li key="acct">
      <Menu
        {...(user
          ? { avatar: { placeholder: user.charAt(0) } }
          : { icon: "user" })}
        items={userItems}
      />
    </li>
  );

  function convertActionToEventHandler(item: MenuItem) {
    if (item === MENU_SEPARATOR || "heading" in item) {
      return item;
    }
    const { action, ...rest } = item;
    return {
      ...rest,
      onClick: action ? () => onAction(action) : () => undefined,
    };
  }

  // flatten all menus for the hamburger version
  const flat = items?.flatMap<AnyMenuItem>((item, index) => {
    // insert sep between flatten mensu
    const sep = index < items.length - 1 ? [MENU_SEPARATOR] : [];
    return "items" in item
      ? [
          { label: item.label, icon: item.icon, heading: true },
          ...item.items.map(convertActionToEventHandler),
          ...sep,
        ]
      : [convertActionToEventHandler(item), ...sep];
  });

  // TODO: support other types of menubar content
  return (
    <nav
      className="menubar user_menubar"
      {...inverse(appearance === "inverse")}
    >
      {logo ? <Logo logo={logo} onAction={onAction} /> : <span />}
      <ul className="desktop">
        {items &&
          items
            .map((item, index) =>
              "items" in item ? (
                <Menu
                  key={index}
                  label={item.label}
                  icon={item.icon}
                  items={item.items.map(convertActionToEventHandler)}
                />
              ) : (
                <MenuBarItem key={index} item={item} onClick={onAction} />
              )
            )
            .map((item, i) => <li key={i}>{item}</li>)}
        {userMenu}
      </ul>
      <ul className="mobile">
        {flat ? (
          <li key="collapsed">
            <Menu icon="menu" items={flat} />
          </li>
        ) : null}
        {userMenu}
      </ul>
    </nav>
  );
}

function MenuBarItem({
  item: { label, action, disabled = false, icon, appearance = "link" },
  onClick,
}: {
  item: MenuButton;
  onClick: OnClickEventTrigger;
}) {
  const logo = icon ? <Icon icon={icon} gap={label} /> : null;

  const isDisabled = disabled || !action || undefined;

  const click = isDisabled ? undefined : () => onClick(action);

  let role: string | undefined;
  switch (appearance) {
    case "link":
      role = isDisabled ? undefined : "link";
      return (
        <span
          role={role}
          className="with_icon"
          aria-disabled={isDisabled}
          onClick={click}
        >
          {logo}
          {label ?? ZW_SPACE}
        </span>
      );

    case "button":
    case "button_outline":
    case "button_secondary":
    case "button_muted":
    default:
      return (
        <button className="with_icon" onClick={click} disabled={isDisabled}>
          {logo}
          {label ?? ZW_SPACE}
        </button>
      );
  }
}

// src + dark
// if (src && dark) -> toggle
// if dark === "invert" -> take src and invert it
// if only src... then omit light_mode
function Logo({
  logo,
  onAction: onClick,
}: {
  logo: Logo;
  onAction: (action: string) => void;
}) {
  const { src, dark, text, action } = logo;
  const click = action ? () => onClick(action) : undefined;
  return src ? (
    dark ? (
      <>
        <img className="light_mode" onClick={click} src={src} alt={text} />
        {dark === "invert" ? (
          <img
            className="dark_mode invert"
            onClick={click}
            src={src}
            alt={text}
          />
        ) : (
          <img className="dark_mode" onClick={click} src={dark} alt={text} />
        )}
      </>
    ) : (
      <img onClick={click} src={src} alt={text} />
    )
  ) : (
    <h5 className={click ? "clickable" : undefined} onClick={click}>
      {text}
    </h5>
  );
}

// TODO fix the type to allow icon/label OR avatar
export const Menu: React.FC<{
  label?: string;
  icon?: string;
  avatar?: { avatar?: string; placeholder: string };
  items: MenuItems;
}> = ({ label, icon, avatar, items }) => {
  const popupMenuProps = useSkyMassPopupMenu(() => {
    return items;
  });

  return (
    <span role="link" className="with_icon menu_parent" {...popupMenuProps}>
      {icon ? (
        <Icon icon={icon} gap={label} />
      ) : avatar ? (
        <div className="avatar">{avatar.placeholder}</div>
      ) : null}
      {label ? label : avatar ? null : ZW_SPACE}
    </span>
  );
};
