import React, { CSSProperties, useEffect } from "react";
import { createPortal } from "react-dom";

type ThemeProps = {
  title?: string;
  favicon?: string;
  fonts?: string[];
  mode?: "dark" | "light";
  direction?: "ltr" | "rtl";
  light?: {};
  dark?: {};
};

function asVar(name) {
  return `--${name.replace(/_/g, "-")}`;
}

export function Theme({
  title,
  favicon,
  fonts,
  mode,
  direction,
  light,
  dark,
}: ThemeProps) {
  useEffect(() => {
    if (mode) {
      document.documentElement.dataset.theme = mode;
    }
    return () => {
      document.documentElement.dataset.theme = undefined;
    };
  }, [mode]);

  useEffect(() => {
    if (!favicon) return;
    const node = document.getElementById("favicon") as HTMLLinkElement;
    if (!node) return;
    const old = node.href;
    node.href = favicon;
    return () => {
      node.href = old;
    };
  }, [favicon]);

  useEffect(() => {
    if (!title) return;
    const old = document.title;
    document.title = title;
    return () => {
      document.title = old;
    };
  }, [title]);

  useEffect(() => {
    if (!direction) return;
    const html = document.documentElement;
    const old = html.getAttribute("dir");
    html.setAttribute("dir", direction);
    return () => {
      html.setAttribute("dir", old || "");
    };
  }, [direction]);

  const light_vars = light
    ? Object.keys(light)
        .map((v) => `${asVar(v)}: ${light[v]};`)
        .join("\n")
    : null;

  const dark_vars = dark
    ? Object.keys(dark)
        .map((v) => `${asVar(v)}: ${dark[v]};`)
        .join("\n")
    : null;

  return createPortal(
    <>
      {light_vars ? (
        <style type="text/css">
          {`
     [data-theme="light"],
     :root:not([data-theme="dark"]) {       
       ${light_vars} 
      }`.trim()}
        </style>
      ) : null}
      {dark_vars ? (
        <style type="text/css">
          {`
      @media only screen and (prefers-color-scheme: dark) {
        :root:not([data-theme]) { 
          ${dark_vars} 
        }
      }
      [data-theme="dark"] { 
        ${dark_vars}
      }`.trim()}
        </style>
      ) : null}
      {fonts?.length ? (
        <link
          href={`https://fonts.googleapis.com/css2?family=${fonts.join("|")}`}
          rel="stylesheet"
        ></link>
      ) : null}
    </>,
    document.head
  );
}

export function inverse(inversed) {
  return {
    "data-theme": inversed ? "dark" : undefined,
    style: inversed
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ({
          "--primary": "var(--primary-inverse)",
          color: "var(--primary)",
        } as CSSProperties)
      : undefined,
  };
}
