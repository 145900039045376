import React, { useEffect, useRef, useState } from "react";

import {
  useFloating,
  autoUpdate,
  flip,
  useRole,
  useDismiss,
  useInteractions,
  useListNavigation,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
  offset,
} from "@floating-ui/react";
import { Icon } from "./Icon";
import { ZW_SPACE } from "./renderValue";

interface BaseItem {
  label?: string;
  icon?: string;
}

export interface MenuItem extends BaseItem {
  disabled?: boolean;
  onClick: () => void;
}

export interface MenuHeadingItem extends BaseItem {
  heading: true;
}

export type MenuItemSeparator = "---";

export const MENU_SEPARATOR: MenuItemSeparator = "---";

export interface MenuReactComponent {
  component: React.FC;
}

export type AnyMenuItem =
  | MenuItem
  | MenuItemSeparator
  | MenuHeadingItem
  | MenuReactComponent;

export type MenuItems = AnyMenuItem[];

export function itemIsMenuItemSeparator(
  item: AnyMenuItem
): item is MenuItemSeparator {
  return item === "---";
}

export function itemIsMenuItemHeading(
  item: AnyMenuItem
): item is MenuHeadingItem {
  return item && Object.prototype.hasOwnProperty.call(item, "heading");
}

export function itemIsMenuReactComponent(
  item: AnyMenuItem
): item is MenuReactComponent {
  return item && Object.prototype.hasOwnProperty.call(item, "component");
}

interface SkyMassContextMenuDetail {
  type: "contextMenuDetail";
  items: MenuItems;
  clientX: number;
  clientY: number;
}

interface SkyMassPopupMenuDetail {
  type: "popupMenuDetail";
  items: MenuItems;
  anchor: HTMLElement;
}

type SkyMassMenuDetail = SkyMassContextMenuDetail | SkyMassPopupMenuDetail;

interface CustomEventMap {
  skyMassPopupMenu: CustomEvent<SkyMassMenuDetail>;
}

declare global {
  interface Document {
    // adds definition to Document, but you can do the same with HTMLElement
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    // adds definition to Document, but you can do the same with HTMLElement
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void
    ): void;
    dispatchEvent<K extends keyof CustomEventMap>(ev: CustomEventMap[K]): void;
  }
}

export type PopupMenuDomEvent =
  | React.MouseEvent<HTMLElement, MouseEvent>
  | React.TouchEvent<HTMLElement>;

type EventCoords = Pick<
  React.MouseEvent<HTMLElement, MouseEvent>,
  "clientX" | "clientY"
>;

export function useSkyMassPopupMenu(
  itemsForEvent: (e: React.MouseEvent<HTMLElement, MouseEvent>) => MenuItems
): React.DOMAttributes<HTMLElement> {
  return {
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();

      e.target.dispatchEvent(
        new CustomEvent<SkyMassPopupMenuDetail>("skyMassPopupMenu", {
          bubbles: true,
          detail: {
            type: "popupMenuDetail",
            items: itemsForEvent(e),
            anchor: e.currentTarget,
          },
        })
      );
    },
  };
}

export function useSkyMassContextMenu(
  itemsForEvent: (e: PopupMenuDomEvent) => MenuItems | undefined
): React.DOMAttributes<HTMLElement> & { "data-custom-context-menu": true } {
  const touchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  function cancelScheduledTouch() {
    if (touchTimeoutRef.current) {
      clearTimeout(touchTimeoutRef.current);
    }
  }

  function dispatchSkyMassContextMenu(
    e: PopupMenuDomEvent,
    { clientX, clientY }: EventCoords
  ) {
    const items = itemsForEvent(e);

    if (items === undefined) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    e.target.dispatchEvent(
      new CustomEvent<SkyMassContextMenuDetail>("skyMassPopupMenu", {
        bubbles: true,
        detail: {
          type: "contextMenuDetail",
          items,
          clientX,
          clientY,
        },
      })
    );
  }

  return {
    // data attribute to prevent the mobile browsers' default selection
    "data-custom-context-menu": true,
    onContextMenu: (e) => {
      // if there's a selection, don't show the custom context menu
      if (window.getSelection()?.isCollapsed) {
        dispatchSkyMassContextMenu(e, e);
      }
    },

    onTouchStart(e) {
      cancelScheduledTouch();
      touchTimeoutRef.current = setTimeout(() => {
        dispatchSkyMassContextMenu(e, e.touches[0]);
        touchTimeoutRef.current = null;
      }, 400);
    },

    onTouchEnd: cancelScheduledTouch,
  };
}

export const PopupMenuHandler: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState<MenuItems>([]);

  const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);
  const allowMouseUpCloseRef = useRef(false);
  const anchorRef = useRef<HTMLElement | null>(null);

  function close() {
    if (anchorRef.current) {
      delete anchorRef.current.dataset.open;
      anchorRef.current = null;
    }
    setIsOpen(false);
  }

  const { refs, x, y, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [flip({ crossAxis: true }), offset(4)],
    placement: "bottom-start",
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);
  const listNavigation = useListNavigation(context, {
    listRef: listItemsRef,
    onNavigate: setActiveIndex,
    activeIndex,
  });

  const { getFloatingProps, getItemProps } = useInteractions([
    role,
    dismiss,
    listNavigation,
  ]);

  useEffect(() => {
    let timeout: number;

    function onSkyMassPopupMenu({ detail }: CustomEvent<SkyMassMenuDetail>) {
      if (detail.type === "contextMenuDetail") {
        const { clientX, clientY } = detail;
        refs.setPositionReference({
          getBoundingClientRect() {
            return {
              width: 0,
              height: 0,
              x: clientX,
              y: clientY,
              top: clientY,
              right: clientX,
              bottom: clientY,
              left: clientX,
            };
          },
        });
      } else {
        detail.anchor.dataset.open = "true";
        anchorRef.current = detail.anchor;
        refs.setPositionReference(detail.anchor);
      }

      setIsOpen(true);
      setItems(detail.items);
      clearTimeout(timeout);

      allowMouseUpCloseRef.current = false;
      timeout = window.setTimeout(() => {
        allowMouseUpCloseRef.current = true;
      }, 300);
    }

    function onMouseUp() {
      if (allowMouseUpCloseRef.current) {
        close();
      }
    }

    document.addEventListener("skyMassPopupMenu", onSkyMassPopupMenu);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("skyMassPopupMenu", onSkyMassPopupMenu);
      document.removeEventListener("mouseup", onMouseUp);
      clearTimeout(timeout);
    };
  }, [refs]);

  if (!isOpen) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll>
        <FloatingFocusManager context={context} initialFocus={refs.floating}>
          <ul
            role="listbox"
            className="popup_menu menu"
            ref={refs.setFloating}
            style={{
              top: `${y ?? 0}px`,
              left: `${x ?? 0}px`,
            }}
            {...getFloatingProps()}
          >
            {items.map((item, index) => {
              if (itemIsMenuItemHeading(item)) {
                const { label, icon } = item;
                return (
                  <li key={index} className="menu_heading" tabIndex={-1}>
                    {icon ? <Icon icon={icon} gap={label} /> : null}
                    {label ?? ZW_SPACE}
                  </li>
                );
              } else if (itemIsMenuItemSeparator(item)) {
                return (
                  <li key={index} role="separator" className="menu_separator">
                    <hr />
                  </li>
                );
              } else if (itemIsMenuReactComponent(item)) {
                const Component = item.component;
                return <Component key={index} />;
              } else {
                const { label, onClick, icon, disabled } = item;
                const eventHandlers = disabled
                  ? {}
                  : {
                      onClick: () => {
                        onClick();
                        close();
                      },
                      onMouseUp: () => {
                        onClick();
                        close();
                      },
                      onKeyDown: (e) => {
                        if (e.key === "Enter") {
                          onClick();
                          close();
                        }
                      },
                    };
                return (
                  <li
                    key={index}
                    aria-disabled={disabled ? true : undefined}
                    {...getItemProps({
                      tabIndex: activeIndex === index ? 0 : -1,
                      ref(node: HTMLButtonElement) {
                        listItemsRef.current[index] = node;
                      },
                      ...eventHandlers,
                    })}
                  >
                    {icon ? <Icon icon={icon} gap={label} /> : null}
                    {label ?? ZW_SPACE}
                  </li>
                );
              }
            })}
          </ul>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};
