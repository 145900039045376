import React, { useEffect } from "react";
import { Icon } from "./widgets/Icon";

export function CloseIcon({ onClick }) {
  useEffect(() => {
    const handle = (e) => e.key === "Escape" && onClick();
    window.addEventListener("keydown", handle);
    return () => window.removeEventListener("keydown", handle);
  }, []);
  return <Icon icon="x" className="close_icon" onClick={onClick} />;
}
