import { MonoTypeOperatorFunction, Observable } from "rxjs";

/**
 * Like `debounceeMinTime` except that the time is calculated per element and
 * then the debounce will clear as soon as the earliest time is triggered.
 */
export function debounceMinTime<T>(
  durationSelector: (value: T) => number
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    new Observable<T>((sink) => {
      /** The current accrued minimum delay */
      let minDelay = 0;
      let timeout: any = 0;
      let scheduledTime = 0;
      let firstValue: any;

      const fireValue = () => {
        const toFire = firstValue;
        timeout = 0;
        firstValue = undefined;

        sink.next(toFire);
      };

      const sub = source.subscribe({
        next(value: T) {
          const delay = durationSelector(value);

          if (!timeout) {
            // this is the first value, start debouncing
            minDelay = delay;
            firstValue = value;
            scheduledTime = Date.now() + delay;
            timeout = setTimeout(fireValue, delay);
          } else {
            // we are actively debouncing
            minDelay = Math.min(delay, minDelay);
            const toSchedule = Date.now() + minDelay;

            // simple optimization to not move the timer if it's less than a frame
            // difference
            if (Math.abs(toSchedule - scheduledTime) > 15) {
              scheduledTime = toSchedule;
              clearTimeout(timeout);
              timeout = setTimeout(fireValue, minDelay);
            }
          }
        },

        complete() {
          sink.complete();
        },

        error(err) {
          sink.error(err);
        },
      });

      return () => {
        if (timeout) {
          timeout = 0;
          firstValue = undefined;
          clearTimeout(timeout);
        }

        sub.unsubscribe();
      };
    });
}
