import {
  ClientUpdate,
  ConsumerData,
  CSeq,
  INVALID_VALUE,
  P2CSetValMsg,
  Path,
  RenderNode,
  RSeq,
} from "./protocol";
import { UpdatableInstance } from "./useUpdatableProps";
import { Draft } from "immer";
import { RegionState, RegionStructure, WidgetStructure } from "./RegionState";
import { areBytesEqual } from "./utils";
import { serializeData } from "./ConsumerManager";
import { PathNode } from "./PathNode";
import { Widget } from "@skymass/skymass/dist/widgets/Widget.mjs";

export class ValInfo<T = any> {
  private readonly region: RegionState;
  readonly pathNode: PathNode;
  readonly key: string;
  private readonly deserialize: (bytes: Uint8Array) => T;

  /**
   * The last time the server declared the value
   */
  lastServerUpdate: RSeq;
  /**
   * The most recent value on the server
   */
  onServerSerialized: Uint8Array;
  onServer: any;

  /**
   * This is the value that we have set this from with the client.
   * undefined means that it has not been set
   */
  locallySet: undefined | Uint8Array = undefined;
  lastLocallySet: CSeq = 0;

  /**
   * This represents the active value of the client (what the UI should show)
   */
  readonly val: UpdatableInstance<T>;

  /**
   * This will shortly be replaced
   */
  readonly errorUpdatable: UpdatableInstance<void | string>;

  constructor(
    region: RegionState,
    path: PathNode,
    key: string,
    deserialize: (bytes: Uint8Array) => T,
    rSeq: RSeq,
    data: ConsumerData
  ) {
    const onServerSerialized = data.current;
    const onServer = deserialize(onServerSerialized);

    this.region = region;
    this.pathNode = path;
    this.key = key;
    this.deserialize = deserialize;
    this.lastServerUpdate = rSeq;
    this.onServerSerialized = onServerSerialized;
    this.onServer = onServer;
    this.val = new UpdatableInstance({
      pending: false,
      val: onServer,
    });
    this.errorUpdatable = new UpdatableInstance<void | string>({
      pending: false,
      val: data.error,
    });
  }

  setVal = (val: T, isValid: boolean = true, debounce: number = 0) => {
    const serialized = new Uint8Array(serializeData(val));
    if (areBytesEqual(this.locallySet, serialized)) return;

    this.lastLocallySet = this.region.updateRegion(
      this.pathNode.toPathArray(),
      this.key,
      serialized,
      isValid,
      debounce
    );
    this.locallySet = serialized;
    this.val.updateContent({ pending: true, val });
  };
}

export class RegionVals {
  private readonly region: RegionState;
  readonly deserialize: (bytes: Uint8Array) => unknown;

  constructor(
    region: RegionState,
    deserialize: (bytes: Uint8Array) => unknown
  ) {
    this.region = region;
    this.deserialize = deserialize;
  }

  crawlTree(before: Draft<RegionStructure>, after: ClientUpdate) {
    const { widgets } = after;
    const prevWidgets = before.widgets;
    const keys = Object.keys(widgets);

    before.cSeq = after.cSeq;
    before.rSeq = after.rSeq;
    before.body = after.body;

    keys.forEach((key) => {
      const update = widgets[key];
      const prev = prevWidgets.get(key);
      if (prev && prev.tag === update.tag) {
        // todo, add a definedSince check
        this.updateWidget(after, prev, update);
      } else {
        const widget = this.makeWidget(after, new PathNode(key), update);
        // @ts-expect-error not sure what this is
        prevWidgets.set(key, widget);
      }
    });

    if (keys.length !== prevWidgets.size) {
      Array.from(prevWidgets.keys()).forEach((key) => {
        if (!keys.includes(key)) prevWidgets.delete(key);
      });
    }
  }

  setValAtPath({ cSeq, update }: P2CSetValMsg) {
    const { path, key, val: bytes, isValid } = update;

    let foundWidget: WidgetStructure | Draft<WidgetStructure> | null = null;
    let children = this.region.currentStructure().widgets;

    for (let i = 0; i < path.length; ++i) {
      foundWidget = children.get(path[i]);
      if (!foundWidget) {
        console.error(`${path.join(".")} not found at ${path[i]}`);
        return;
      }
      children = foundWidget.children;
    }

    const info = foundWidget.vals.get(key);
    if (!info) {
      console.error(`${key} not found`);
      return;
    }

    if (!areBytesEqual(info.onServerSerialized, bytes)) {
      info.onServerSerialized = bytes;
      info.onServer = this.deserialize(bytes);
    }

    // this function is only ever called when the server has explicitly set
    // the value, and because the server gets first choice in the protocol,
    // we are just going to set ourselves to the server value.

    // console.log({ info });

    info.lastServerUpdate = update.rSeq;
    if (info.lastLocallySet !== 0) {
      info.locallySet = null;
      info.lastLocallySet = 0;
    }
    info.val.updateVal(info.onServer);
  }

  private updateVal(info: ValInfo, bytes: Uint8Array, cSeq: number) {}

  makeWidget(
    topLevel: ClientUpdate,
    pathNode: PathNode,
    node: RenderNode
  ): WidgetStructure {
    const { vals, children } = node;
    const valInfos = new Map<string, ValInfo>();

    Object.keys(vals).forEach((key) => {
      const data = vals[key];
      valInfos.set(key, this.makeInfo(topLevel.rSeq, pathNode, key, data));
    });

    let childrenMap: null | Map<string, WidgetStructure> = null;
    if (children) {
      childrenMap = new Map();
      const keys = Object.keys(children);
      for (let i = 0; i < keys.length; i++) {
        const childId = keys[i];

        childrenMap.set(
          childId,
          this.makeWidget(topLevel, pathNode.child(childId), children[childId])
        );
      }
    }

    const methodHandlers = new Map<string, (args: any) => void>();

    function callMethod(methodName: string, args: any) {
      methodHandlers.get(methodName)?.(args);
    }

    function setMethodHandler(
      methodName: string,
      handler: (args: any) => void
    ) {
      methodHandlers.set(methodName, handler);
    }

    return {
      pathNode,
      tag: node.tag,
      vals: valInfos,
      props: node.props,
      children: childrenMap,
      callMethod,
      setMethodHandler,
    };
  }

  updateWidget(
    topLevel: ClientUpdate,
    widget: Draft<WidgetStructure>,
    update: RenderNode
  ) {
    const { vals, children } = update;

    widget.tag = update.tag;
    widget.props = update.props;

    // consolidate values
    const prevVals = widget.vals;

    let toDelete = null;
    prevVals.forEach((_, key) => {
      if (!vals.hasOwnProperty(key)) {
        if (!toDelete) {
          toDelete = [key];
        } else {
          toDelete.push(key);
        }
      }
    });

    toDelete?.forEach((key) => {
      prevVals.delete(key);
    });

    Object.keys(vals).forEach((key) => {
      const data = vals[key];
      let info = prevVals.get(key);

      // todo: full algorithm

      if (!info) {
        info = this.makeInfo(topLevel.rSeq, widget.pathNode, key, data);
      } else {
        const bytes = data.current;
        if (!areBytesEqual(info.onServerSerialized, bytes)) {
          info.onServerSerialized = bytes;
          info.onServer = this.deserialize(bytes);
        }

        // todo: add better consolidation logic that would allow the server to
        // change the value.
        const lastLocallySet = info.lastLocallySet;
        const lastServerUpdate = data.lastProducerUpdate;

        const serverHasSeenLocalUpdate = lastLocallySet <= topLevel.cSeq;
        const serverHasUpdatedValue = info.lastServerUpdate < lastServerUpdate;

        info.lastServerUpdate = lastServerUpdate;

        if (serverHasSeenLocalUpdate || serverHasUpdatedValue) {
          if (lastLocallySet !== 0) {
            info.locallySet = null;
            info.lastLocallySet = 0;
          }
          info.val.updateVal(info.onServer);
        }

        info.errorUpdatable.updateVal(data.error);
      }

      prevVals.set(key, info);
    });

    // consolidate children
    const prevChildren = widget.children;
    let childrenMap = prevChildren;

    const childIds = children && Object.keys(children);
    childIds?.forEach((key) => {
      const child = children[key];
      const prevChild = prevChildren?.get(key);
      if (prevChild) {
        // todo, add a definedSince check
        this.updateWidget(topLevel, prevChild, child);
      } else {
        if (!childrenMap) childrenMap = new Map();
        const childWidget = this.makeWidget(
          topLevel,
          widget.pathNode.child(key),
          child
        );

        // @ts-expect-error
        childrenMap.set(key, childWidget);
      }
    });

    if (prevChildren && childIds.length !== prevChildren.size) {
      Array.from(prevChildren.keys()).forEach((key) => {
        if (!childIds.includes(key)) prevChildren.delete(key);
      });
    }
  }

  private makeInfo(
    rSeq: RSeq,
    pathNode: PathNode,
    key: string,
    data: ConsumerData
  ): ValInfo {
    return new ValInfo(
      this.region,
      pathNode,
      key,
      this.deserialize,
      rSeq,
      data
    );
  }
}

let _INVALID_VALUE = null;
function serializedInvalid() {
  if (!_INVALID_VALUE) {
    _INVALID_VALUE = new Uint8Array(serializeData(INVALID_VALUE));
  }
  return _INVALID_VALUE;
}

function readKey<T>(
  record: undefined | Record<string, T>,
  key: string
): undefined | T {
  return record && record.hasOwnProperty(key) ? record[key] : undefined;
}
