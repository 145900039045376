import { ObservableNotification } from "rxjs";
import { UpdatableInstance, Updating } from "./useUpdatableProps";

// do not use the class directly, use INVALID_VALUE
export class InvalidValue {
  private constructor() {}
  toString() {
    return "[Object INVALID_VALUE]";
  }
}

// @ts-expect-error
export const INVALID_VALUE = new InvalidValue();

export enum AdvancedSerializeTypes {
  UNRECOGNIZED, // this is used just for errors
  Subject,
  Date,
  InvalidValue,
  ValueUnchanged,
  KeyDeleted,
}

// the sequence numbers, types used to make code more clear
export type RSeq = number;
export type CSeq = number;

export type MarkdownDeclaration = {
  unparsed: string;
  args: Updating<string>[];
};

export type RenderElement =
  | {
      type: "md";
      data: MarkdownDeclaration;
    }
  | {
      type: "string";
      data: string;
    }
  | {
      type: "placeholder";
      /** the id of the widget (for now) */
      data: string;
    };

export type ConsumerData = {
  definedSince: RSeq;
  lastProducerUpdate: RSeq;
  current: Uint8Array;
  error?: string;
};

export type RenderNode = {
  tag: string;
  props: any;
  /** maps the val name (eg. "val" or "sort") to its data */
  vals: Record<string, ConsumerData>;
  children?: Record<string, RenderNode>;
};

export type ClientUpdate = {
  rSeq: number;
  cSeq: number;
  widgets: Record<string, RenderNode>;
  body: RenderElement[];
};

export enum RegionType {
  /** These regions should render as a modal */
  Modal,
  /** These regions needs to be redeclared every render or it unmounts */
  Component,
  /** These regions stay until they close or are replaced */
  Launched,
  /** These regions totally replace their parent, until they are closed */
  Cover,
  /** These regions are super-modals (things like a confirm dialog) */
  Alert,
}

export const RootRegionId = "__root";

export enum BuiltinActions {
  refresh = "__refresh",
}

export enum LogLevel {
  Log = "log",
  Info = "info",
  Warn = "warn",
  Error = "error",
}

export type LogLine = {
  level: LogLevel;
  content: unknown[];
};

export type DeclaredToast = {
  msg: string;
};

export type P2CRegionCreatedMsg = {
  type: "created";
  regionId: string;
  data: {
    refId: string;
    type: RegionType;
    parentId?: string;
  };
};

export type P2CSetValMsg = {
  type: "set";
  regionId: string;
  cSeq: CSeq;
  update: C2PUpdate;
};

export type P2CRegionUpdateMsg =
  | { type: "nochange"; regionId: string; data: { cSeq: number; rSeq: number } }
  | {
      type: "updating";
      regionId: string;
    }
  | {
      type: "updated";
      regionId: string;
      isPatch?: boolean;
      data: ClientUpdate;
    }
  | P2CSetValMsg;

export type P2CCmdMsg = {
  type: "cmd";
  regionId: string;
  path: Path;
  command: string;
  args: unknown[];
};

export type P2CMethodMsg = {
  type: "method";
  regionId: string;
  path: Path;
  methodName: string;
  args: unknown[];
};

export type P2CMsg =
  | P2CRegionCreatedMsg
  | P2CRegionUpdateMsg
  | P2CCmdMsg
  | P2CMethodMsg
  | {
      type: "removed";
      regionId: string;
    }
  | {
      type: "subject";
      slotId: string;
      // the "P" stands for pending
      data: ObservableNotification<any> | { kind: "P" };
    }
  | { type: "toast"; data: DeclaredToast }
  | {
      type: "history";
      data: { op: "push" | "replace" | "reset"; path: string };
    }
  | { type: "log"; data: LogLine };

export type InitialPageParams = { url: string };

export type C2SConnectMsg = {
  type: "connect";
  cookies?: { session: string; sig: string } | null;
  subdomain: string;
  path: string;
  params: InitialPageParams;
  msgCounter: number;
  uaUUID: string;
};

export type C2SMsg = C2SConnectMsg;

export type S2CConnectError =
  | {
      type: "auth_error";
      code: 401 | 403;
    }
  | {
      type: "no_producer";
      code: 503;
    }
  | {
      type: "no_subdomain";
      code: 410;
    };

export type S2CProducerDied = {
  type: "producer_died";
  code: 502;
};

export type S2CMsg =
  | S2CConnectError
  | S2CProducerDied
  | {
      type: "debug_refresh";
      code: 205;
      path?: string;
    };

export type Path = ReadonlyArray<string>;

export type C2PUpdate = {
  path: Path;
  key: string;
  val: Uint8Array;
  isValid: boolean;
  rSeq: RSeq;
};

export type C2PTranscript = {
  type: "transcript";
  cSeq: CSeq;
  // [RegionId, Update]
  updates: [string, C2PUpdate][];
};

export type C2PMsg = C2PTranscript;
