import { useState, useRef } from "react";

export function Copy({ children, ready }) {
  const [copied, setCopied] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div style={{ position: "relative" }}>
      <code
        style={{
          position: "absolute",
          fontSize: "10px",
          top: "0.5em",
          right: "0.5em",
        }}
        onClick={(e) => {
          if (!ready) {
            return focusToSetup();
          }
          if (!ref.current) return;
          navigator.clipboard.writeText(ref.current.innerText);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}
      >
        {copied ? "COPIED!" : "COPY"}
      </code>
      <div ref={ref}>{children}</div>
    </div>
  );
}

export function focusToSetup() {
  const email = document.getElementById("email");
  email.scrollIntoView({ behavior: "smooth", block: "center" });
  email.focus({ preventScroll: true });
  return;
}
