import React, { ReactElement, ReactNode, useContext, useMemo } from "react";
import { devLog } from "../DevHub";
import { LogLevel } from "../protocol";
import { ValContext, ValContextType, WidgetContext } from "../server_hooks";
import { useUpdatableProps } from "../useUpdatableProps";

export type WidgetProps = {
  id: string;
  label?: ReactNode;

  // maybe these should be passed down by context instead of directly?
  inRow?: boolean;
  rowHasLabel?: boolean;
};

let widgetImpls: Record<string, (props: any) => ReactElement> = {};
export function setWidgetImpls(
  impls: Record<string, (props: any) => ReactElement>
) {
  widgetImpls = impls;
}

export function Widget({ id, label, inRow, rowHasLabel }: WidgetProps) {
  const parentValContext = useContext(ValContext);
  const { widgets, locked } = useContext(WidgetContext);

  const widget = widgets.get(id) || null;

  const valContext = useMemo<ValContextType>(
    () => ({
      onUpdate: parentValContext.onUpdate,
      vals: widget ? widget.vals : new Map(),
    }),
    [widget?.vals]
  );

  let props = widget ? widget.props : {};
  if (label != null) {
    props = { ...props, label: { pending: false, val: label } };
  }

  const liveProps = useUpdatableProps(props);

  // Sometimes a widget may be mentioned in the markdown but does not actually
  // exist. This can be legitimate if it is placing a launched region, but
  // perhaps we should make the user say something explicit to help prevent
  // fat-finger errors from going silent.
  if (!widget) {
    return null;
  }

  const { tag, children } = widget;

  if (!widgetImpls.hasOwnProperty(tag)) {
    devLog(`Unrecognized widget type ${tag}`, LogLevel.Error);
    return null;
  }

  const Impl = widgetImpls[tag];
  return (
    <ValContext.Provider value={valContext}>
      <WidgetContext.Provider
        value={{
          widget,
          widgets: children!,
          locked,
        }}
      >
        <Impl {...liveProps} key={id} inRow={inRow} rowHasLabel={rowHasLabel} />
      </WidgetContext.Provider>
    </ValContext.Provider>
  );
}
