import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { serializeData } from "./ConsumerManager";
import { PathNode } from "./PathNode";
import { ConsumerData } from "./protocol";
import { WidgetStructure } from "./RegionState";
import { RegionVals, ValInfo } from "./RegionVals";
import { useUpdatable } from "./useUpdatableProps";
import { useOnSubmit } from "./widgets/FormWidget";
import { navigateTo } from "./widgets/History";

export type WidgetContextType = Readonly<{
  widget: WidgetStructure;
  widgets: ReadonlyMap<string, WidgetStructure>;
  locked: boolean;
}>;

export type ValContextType = Readonly<{
  onUpdate: (
    isBlocking: boolean,
    path: ReadonlyArray<string>,
    key: string,
    val: Uint8Array,
    isValid: boolean,
    debounce: number
  ) => void;
  vals: ReadonlyMap<string, ValInfo>;
}>;

export const ValContext = createContext<ValContextType>(null);

export const WidgetContext = createContext<WidgetContextType>(null);

/**
 *
 * @param key The id of the value being changed
 * @returns A setter function
 */
export function useEvent<T>(key: string) {
  const { onUpdate, vals } = useContext(ValContext);
  const onEvent = (val: T, isBlocking: boolean = false) => {
    onUpdate(
      isBlocking,
      vals.get(key)!.pathNode.toPathArray(),
      key,
      new Uint8Array(serializeData(val)),
      true,
      0
    );
  };

  return onEvent;
}

export function useAction<T>(key: string) {
  const onEvent = useEvent(key);
  const onAction = (value: T, action: string, isBlocking: boolean = false) => {
    if (action.startsWith("nav:")) {
      navigateTo(new URL(action).pathname);
    } else {
      onEvent(value, isBlocking);
    }
  };
  return onAction;
}

export function useRegionEvent<T>(key: string, isBlocking: boolean = false) {
  const { onUpdate } = useContext(ValContext);
  const onEvent = (val: T) => {
    onUpdate(isBlocking, [], key, new Uint8Array(serializeData(val)), true, 0);
  };

  return onEvent;
}

/**
 * Use this when you only want to set a value and do not wish to re-render when
 * it changes
 *
 * @param key The key to set
 * @returns The setter
 */
export function useSetVal<T>(key: string) {
  const { onUpdate, vals } = useContext(ValContext);
  const setVal = (val: T) => {
    onUpdate(
      false,
      vals.get(key)!.pathNode.toPathArray(),
      key,
      new Uint8Array(serializeData(val)),
      true,
      0
    );
  };

  return setVal;
}

export function useLocked(): boolean {
  return useContext(WidgetContext).locked;
}

/**
 *
 * @param key The id of the value being changed
 * @returns A setter function
 */
export function useControlledVal<T>(key: string = "val") {
  const { vals } = useContext(ValContext);
  const info: ValInfo<T> = vals.get(key)!;
  const val = useUpdatable(info.val);
  const error = useUpdatable(info.errorUpdatable);

  return {
    setVal: info.setVal,
    val: val.val,
    error: error.val,

    // for now, we want to leave out the flash
    disabled: false,
  };
}

export function useValidatedVal<T>(
  key: string = "val",
  customValidate?: (value: T | undefined) => string,
  deps: unknown[] = []
) {
  const { vals } = useContext(ValContext);
  const info = vals.get(key);

  const val = useUpdatable<T>(info!.val);
  const [shownError, setShownError] = useState<string | undefined>(undefined);
  const ref = useRef<any>();
  const focusRef = useRef<any>();

  const manager = useMemo(() => {
    let managerVal = val.val;
    let showState = false;

    const computeError = () => {
      const input: HTMLInputElement = ref.current;

      if (!input) {
        return customValidate?.(managerVal) || "";
      }

      // TODO: maybe check for custom Error first?

      // check for a native error first
      input.setCustomValidity("");
      input.checkValidity();
      let error = input.validationMessage;
      // TODO: there might be multiple validity errors - we are assuming patternMismatch is the one being shown
      // we should make sure the error we are replacing with our custom msg is the patternMismatch error
      if (input.validity.patternMismatch && input.dataset.patternhint) {
        error = input.dataset.patternhint;
      }

      // if there is not a native error, run our custom check
      if (!error) {
        error = customValidate?.(managerVal) || "";
        if (error) {
          input.setCustomValidity(error);
        }
      }

      return error;
    };

    return {
      onSubmit(takeFocusIfInvalid: boolean) {
        const error = computeError();

        // on submit, we should always switch to showing errors or the green
        // checkmark
        showState = true;
        setShownError(error);

        if (error && takeFocusIfInvalid) {
          if (focusRef.current) {
            focusRef.current.focus?.();
            focusRef.current.scrollIntoView?.({ block: "center" });
          } else if (ref.current) {
            ref.current.focus?.();
            ref.current.scrollIntoView?.({ block: "center" });
          }
        }

        return !error;
      },

      checkErrors() {
        // if (showError) return;

        const error = computeError();
        if (error) {
          showState = true;
          setShownError(error);
        } else {
          setShownError(showState ? error : undefined);
        }
      },

      /**
       * @param val The value that the ref element has been set to
       * @returns whether we should upload the value to the server
       */
      onSetVal(val: T): boolean {
        managerVal = val;

        const error = computeError();

        // if we were showing state, update the error, else, leave is out
        setShownError(showState ? error : undefined);

        return !error;
      },
    };
  }, deps);

  useOnSubmit(manager.onSubmit);

  // const serverError = useUpdatable(onServer.error);

  return {
    // val: val !== undefined || onServer === INVALID_VALUE ? val : onServer,
    val: val.val,
    ref,
    focusRef,
    error: shownError,

    checkErrors: manager.checkErrors,

    setVal(val: T, debounce?: number) {
      // calculate any errors
      const isValid = manager.onSetVal(val);

      // update the state
      info!.setVal(val, isValid, debounce);
    },
  };
}

export function useHandleMethodCall(
  methodName: string,
  handler: (args: any) => void
) {
  useContext(WidgetContext).widget.setMethodHandler(methodName, handler);
}
