import React, { useContext } from "react";
import {
  FIXED_GAP,
  SPRING,
  VERTICAL_SEP,
  WidgetFlexData,
  WidgetGroupData,
  WidgetGroupEl,
  isWidgetId,
  GroupAlign,
} from "./doc";
import { WidgetContext } from "./server_hooks";
import { Widget } from "./widgets/Widget";
import { cx } from "./widgets/renderValue";

export type RowProps = {
  inRow?: boolean;
  rowHasLabel?: Boolean;
};

export function isButtonLike(tag) {
  switch (tag) {
    case "Button":
    case "Link":
    case "NavButton":
    case "Download":
      return true;
  }
  return false;
}

function inlineLabel(tag) {
  switch (tag) {
    case "Button":
    case "Link":
    case "Download":
    case "Table":
    case "Boolean":
      return true;
  }
  return false;
}

export function Row({ data }: { data: WidgetGroupData[] }) {
  const { widgets } = useContext(WidgetContext);

  // just the widgets
  const mdWidgets = data.filter((el) => el.type === WidgetGroupEl.Widget);

  // if we are rendering more than one widget
  const multi = mdWidgets.length > 1;

  // force row labels only if more than one widget
  // and any element has a label and skip inline labels.
  const rowHasLabel =
    multi &&
    mdWidgets
      .flatMap((el) =>
        el.type === WidgetGroupEl.Widget
          ? el.data.widgetId.trim().split(",").filter(isWidgetId)
          : []
      )
      .some((wid) => {
        const widget = widgets.get(wid);
        if (!widget) return false;
        if (inlineLabel(widget.tag)) return false;
        return widget.props.label != null;
      });

  const fragment: JSX.Element[] = [];

  // wrap buttons in a div so their layout is isolated from the
  // parent flex box layout
  let buttons: JSX.Element[] = [];

  function flushButtons() {
    if (buttons.length === 0) return;
    fragment.push(<div className="button_group">{buttons}</div>);
    buttons = [];
  }

  let span = 1;
  data.forEach((el, index) => {
    if (el.type === WidgetGroupEl.Spring) {
      flushButtons();

      fragment.push(<span key={String(index)} />);
      return;
    }

    const { widgetId, mdLabel, align } = el.data;

    const bits = widgetId.trim().split(",");

    if (bits.length > 1) {
      flushButtons();

      const data = bits.map((bit): WidgetFlexData => {
        if (bit === "-") {
          return FIXED_GAP;
        } else if (bit === "~") {
          // TODO: should springs be allowed in flex?
          return SPRING;
        } else if (bit === "|") {
          return VERTICAL_SEP;
        } else {
          return {
            type: WidgetGroupEl.Widget,
            data: {
              widgetId: bit,
              mdLabel: undefined,
            },
          };
        }
      });

      fragment.push(
        <Flex
          align={align}
          data={data}
          rowHasLabel={rowHasLabel}
          key={String(index)}
        />
      );
    } else {
      const widget = widgets.get(widgetId);

      // missing widgets are rendered a springs
      if (!widget) {
        flushButtons();

        fragment.push(<span key={String(index)} />);
        return;
      }

      if (isButtonLike(widget.tag)) {
        buttons.push(
          <Widget
            key={widgetId}
            id={widgetId}
            inRow={multi}
            rowHasLabel={rowHasLabel}
          />
        );
        return;
      } else {
        flushButtons();
      }

      // consequitive widgetIds cause a span to form
      if (index + 1 < data.length) {
        const next = data[index + 1];
        if (
          next.type === WidgetGroupEl.Widget &&
          widgetId === next.data.widgetId
        ) {
          span++;
          return;
        }
      }

      const node = (
        <Widget
          key={widgetId}
          id={widgetId}
          label={mdLabel}
          inRow={multi}
          rowHasLabel={rowHasLabel}
        />
      );

      fragment.push(
        <div
          key={widgetId}
          className={cx(
            "grid-span",
            span > 1 && `grid-span-${span}`,
            align !== "default" && `grid-align-${align}`
          )}
        >
          {node}
        </div>
      );

      span = 1;
    }
  });

  flushButtons();

  return fragment.length > 1 ? (
    <div className="grid in_row">{fragment}</div>
  ) : (
    fragment[0]
  );
}

export function Grid({ rows }: { rows: Array<WidgetGroupData[]> }) {
  const { widgets } = useContext(WidgetContext);

  const jsxRows: JSX.Element[][] = [];

  const spannedOverSlots = new Set<string>();
  const renderLater = new Map<string, JSX.Element>();

  rows.forEach((data, rowIndex) => {
    const rowNum = rowIndex + 1;

    const mdWidgets = data
      .flat(1)
      .filter((el) => el.type === WidgetGroupEl.Widget);

    const fragment: JSX.Element[] = [];

    // wrap buttons in a div so their layout is isolated from the
    // parent flex box layout
    let buttons: JSX.Element[] = [];

    let buttonBufferLocation: { row: number; col: number } | null = null;

    function flushButtons() {
      if (buttons.length === 0) return;
      if (buttonBufferLocation === null) {
        throw new Error(
          "Invariant: no button location specified when flushing a button"
        );
      }

      fragment.push(
        <div
          key={`${buttonBufferLocation.row},${buttonBufferLocation.col}`}
          className="button_group"
          style={{
            gridArea: `${buttonBufferLocation.row} / ${buttonBufferLocation.col} / span 1 / span ${buttons.length}`,
          }}
        >
          {buttons}
        </div>
      );

      buttons = [];
      buttonBufferLocation = null;
    }

    // if we are rendering more than one widget
    const multi = mdWidgets.length > 1;

    // traverse the widgets on the row (skipping the ones who are spanned over)
    const rowHasLabel =
      multi &&
      data.some((el, index) => {
        const key = `${rowNum},${index + 1}`;
        if (spannedOverSlots.has(key)) {
          return false;
        }
        const slotWidgets =
          el.type === WidgetGroupEl.Widget
            ? el.data.widgetId.trim().split(",").filter(isWidgetId)
            : [];
        return slotWidgets.some((widgetId) => {
          const widget = widgets.get(widgetId);
          if (!widget) return false;
          if (inlineLabel(widget.tag)) return false;
          return widget.props.label != null;
        });
      });

    data.forEach((el, index) => {
      const colNum = index + 1;
      let colSpan = 1;
      let rowSpan = 1;
      const key = `${rowNum},${colNum}`;

      function cellStyle() {
        return {
          gridArea: `${rowNum} / ${colNum} / span ${rowSpan} / span ${colSpan}`,
        };
      }

      if (renderLater.has(key)) {
        fragment.push(renderLater.get(key)!);
        renderLater.delete(key);
        return;
      }

      if (spannedOverSlots.has(key)) {
        return;
      }

      if (el.type === WidgetGroupEl.Spring) {
        flushButtons();
        fragment.push(<span key={key} style={cellStyle()} />);
        return;
      }

      const { widgetId, mdLabel, align } = el.data;

      const bits = widgetId.trim().split(",");

      if (bits.length > 1) {
        flushButtons();

        const data = bits.map((bit): WidgetFlexData => {
          if (bit === "-") {
            return FIXED_GAP;
          } else if (bit === "~") {
            // TODO: should springs be allowed in flex?
            return SPRING;
          } else if (bit === "|") {
            return VERTICAL_SEP;
          } else {
            return {
              type: WidgetGroupEl.Widget,
              data: {
                widgetId: bit,
                mdLabel: undefined,
              },
            };
          }
        });

        fragment.push(
          <Flex
            data={data}
            rowHasLabel={rowHasLabel}
            style={cellStyle()}
            key={String(index)}
            align={align}
          />
        );
      } else {
        const widget = widgets.get(widgetId);

        // missing widgets are rendered a springs
        if (!widget) {
          flushButtons();
          fragment.push(<span key={key} style={cellStyle()} />);
          return;
        }

        if (isButtonLike(widget.tag)) {
          if (buttonBufferLocation === null) {
            buttonBufferLocation = { row: rowNum, col: colNum };
          }
          buttons.push(
            <Widget
              key={widgetId}
              id={widgetId}
              inRow={multi}
              rowHasLabel={rowHasLabel}
            />
          );
          return;
        } else {
          flushButtons();
        }

        const node = (
          <Widget
            key={widgetId}
            id={widgetId}
            label={mdLabel}
            inRow={multi}
            rowHasLabel={rowHasLabel}
          />
        );

        let nextIndex = index + 1;

        while (nextIndex < data.length) {
          const next = data[nextIndex];
          if (
            next.type === WidgetGroupEl.Widget &&
            widgetId === next.data.widgetId
          ) {
            colSpan++;
            spannedOverSlots.add(`${rowNum},${nextIndex + 1}`);
            nextIndex++;
          } else {
            break;
          }
        }

        let nextRowIndex = rowIndex + 1;
        while (nextRowIndex < rows.length) {
          const nextRow = rows[nextRowIndex];
          const itemsBelow = nextRow.slice(index, index + colSpan);
          if (
            itemsBelow.every(
              (el) =>
                el.type === WidgetGroupEl.Widget &&
                el.data.widgetId === widgetId
            )
          ) {
            for (let colIndex = index; colIndex < index + colSpan; colIndex++) {
              spannedOverSlots.add(`${nextRowIndex + 1},${colIndex + 1}`);
            }
            rowSpan++;
            nextRowIndex++;
          } else {
            break;
          }
        }

        const rendered = (
          <div
            key={widgetId}
            style={cellStyle()}
            className={cx(align !== "default" && `grid-align-${align}`)}
          >
            {node}
          </div>
        );

        if (rowSpan === 1) {
          fragment.push(rendered);
        } else {
          renderLater.set(`${nextRowIndex},${index + 1}`, rendered);
        }

        colSpan = 1;
      }
    });

    flushButtons();
    jsxRows.push(fragment);
  });

  return (
    <div
      className="widgets_grid"
      style={{
        gridTemplateRows: `repeat(${jsxRows.length - 1}, auto) 1fr`,
      }}
    >
      {jsxRows.flat(1)}
    </div>
  );
}

export function Flex({
  data,
  rowHasLabel = false,
  style,
  align,
}: {
  data: WidgetFlexData[];
  rowHasLabel?: boolean;
  style?: React.CSSProperties;
  align?: GroupAlign;
}) {
  const { widgets } = useContext(WidgetContext);

  // just the widgets
  const mdWidgets = data.filter((el) => el.type === WidgetGroupEl.Widget);

  // if we are rendering more than one widget
  const multi = mdWidgets.length > 1;

  // need to force row labels only if more than one widget
  // need to skip ove buttons.
  // button labels are inline and don't count towards this
  // TODO: same with checkboxes :-(
  const anyHaveLabel =
    rowHasLabel ||
    (multi &&
      mdWidgets.some((el) => {
        if (el.type !== WidgetGroupEl.Widget) return false;
        const widget = widgets.get(el.data.widgetId);
        if (!widget) return false;
        if (inlineLabel(widget.tag)) return false;
        return el.data.mdLabel != null || widget.props.label != null;
      }));

  const fragment: JSX.Element[] = [];

  // wrap buttons in a div so their layout is isolated from the
  // parent flex box layout
  let buttons: JSX.Element[] = [];

  function flushButtons() {
    if (buttons.length === 0) return;
    fragment.push(<div className="button_group">{buttons}</div>);
    buttons = [];
  }

  data.forEach((el, index) => {
    // missing widgets are rendered a springs
    if (el.type === WidgetGroupEl.Spring) {
      fragment.push(<div className="spring" key={String(index)} />);
      flushButtons();
      return;
    } else if (el.type === WidgetGroupEl.FixedGap) {
      fragment.push(<div className="row_gap" key={String(index)} />);
      flushButtons();
      return;
    } else if (el.type === WidgetGroupEl.VerticalSeparator) {
      fragment.push(<div className="vertical_sep" key={String(index)} />);
      flushButtons();
      return;
    }

    const { widgetId, mdLabel } = el.data;

    const widget = widgets.get(widgetId);

    if (!widget) {
      fragment.push(<div className="spring" key={String(index)} />);
      flushButtons();
      return;
    }

    if (isButtonLike(widget.tag)) {
      buttons.push(
        <Widget
          key={widgetId}
          id={widgetId}
          inRow={multi}
          rowHasLabel={anyHaveLabel}
        />
      );
      return;
    }

    flushButtons();

    fragment.push(
      <Widget
        key={widgetId}
        id={widgetId}
        label={mdLabel}
        inRow={multi}
        rowHasLabel={anyHaveLabel}
      />
    );
  });

  flushButtons();

  return (
    <div
      className={cx(
        "flex in_row",
        align && align !== "default" && `flex-align-${align}`
      )}
      style={style}
    >
      {fragment}
    </div>
  );
}
