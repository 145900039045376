import React, { ReactNode } from "react";
import { Updatable } from "../useUpdatableProps";
import { ZW_SPACE } from "./renderValue";

export function MaybeLabel({
  label = undefined,
  rowHasLabel = false,
}: {
  label?: Updatable<ReactNode> | string;
  rowHasLabel?: Boolean | boolean;
}) {
  const lbl = valOrLiteral(label);
  if (lbl) {
    return <label className="lbl">{lbl}</label>;
  } else if (rowHasLabel) {
    return <label className="lbl zeroWidthSpace">{ZW_SPACE}</label>;
  }
  return null;
}

export function valOrLiteral<T>(x: T | { val: T }): T {
  return typeof x === "object" && x != null && "val" in x ? x.val : x;
}
