import React, { useEffect } from "react";
import { useEvent, useControlledVal } from "../server_hooks";
import { getSubdomainAndPath, SKYMASS_HOSTS } from "../paths";

const HIST_EVENT = "__sm_history";

// chrome requires some page interaction for pushstate to stick
// TODO: this should only be done if PreventExit is used?
const INIT = { __sm_init: true, depth: 1 };
function init() {
  history.pushState(INIT, "");
  removeEventListener("mousedown", init);
}

addEventListener("mousedown", init);

export function History() {
  const onPopState = useEvent("PopState");

  const val = useControlledVal<string | undefined>("val");
  const url = useControlledVal<string | undefined>("url");
  const route = useControlledVal<string | undefined>("route");

  useEffect(() => {
    function handlePopState(e: PopStateEvent) {
      const currentUrl = document.location.href.toString();
      url.setVal(currentUrl);
      route.setVal(getSubdomainAndPath(currentUrl).route);
      onPopState(val.val);
    }
    addEventListener("popstate", handlePopState);
    return () => removeEventListener("popstate", handlePopState);
  }, [val.val]);

  useEffect(() => {
    function handleHistory(e: CustomEvent) {
      url.setVal(e.detail.url);
      route.setVal(getSubdomainAndPath(e.detail.url).route);
    }
    addEventListener(HIST_EVENT, handleHistory as EventListener);
    return () =>
      removeEventListener(HIST_EVENT, handleHistory as EventListener);
  }, []);

  return <></>;
}

export function useCurrentRoute() {
  const [routeValue, setRouteValue] = React.useState<string | undefined>(
    () => getSubdomainAndPath(location.href).route
  );
  useEffect(() => {
    function handlePopState() {
      setRouteValue(getSubdomainAndPath(location.href).route);
    }
    addEventListener("popstate", handlePopState);

    function handleHistory(e: CustomEvent) {
      setRouteValue(getSubdomainAndPath(e.detail.url).route);
    }
    addEventListener(HIST_EVENT, handleHistory as EventListener);

    return () => {
      removeEventListener(HIST_EVENT, handleHistory as EventListener);
      removeEventListener("popstate", handlePopState);
    };
  }, []);
  return routeValue;
}

export function PreventExit() {
  useEffect(() => {
    function handler(e: PopStateEvent) {
      console.log(e.state);
      if (e.state?.__sm_init) {
        history.pushState(INIT, "");
        history.forward();
      }
    }

    addEventListener("popstate", handler);
    return () => removeEventListener("popstate", handler);
  }, []);

  return <></>;
}

function abs_url_to_route(base: string, route: string) {
  const loc = new URL(base);
  // TODO: test this

  const no_double_slashes = loc.pathname.split("/").filter((x) => x !== "");

  // TODO: also clean route from double slashes etc..

  let base_path: string[], routes: string[];
  if (SKYMASS_HOSTS.includes(loc.hostname)) {
    // skymass.dev/app/<subdomain>/<app>/
    const [app, subdomain, path = "", ...rest] = no_double_slashes;
    base_path = [app, subdomain, path];
    routes = rest;
  } else {
    const [path = "", ...rest] = no_double_slashes;
    // alpha.planetfwd.com/<app>
    base_path = [path];
    routes = rest;
  }

  if (route.startsWith("?")) {
    const url = new URL(["", ...base_path, ...routes].join("/"), loc.href);
    url.search = route;
    return url;
  } else if (route.startsWith("./")) {
    // relative to current route
    return new URL(["", ...base_path, ...routes, route].join("/"), loc.href);
  } else if (route.startsWith("/")) {
    // reset route
    return new URL(["", ...base_path, `.${route}`].join("/"), loc.href);
  } else {
    // replace last element of route
    return new URL(
      ["", ...base_path, ...routes.slice(0, -1), route].join("/"),
      loc.href
    );
  }
}

// function t(base, route) {
//   console.log(
//     `t("${base}", "${route}", "${abs_url_to_route(base, route).toString()}");`
//   );
// }

// function tests() {
//   let u = "http://127.0.0.1:8888/app/skymass/router";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://127.0.0.1:8888/app/skymass/router/";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://127.0.0.1:8888/app/skymass/router/apple";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://127.0.0.1:8888/app/skymass/router/apple/foo/";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://127.0.0.1:8888/app/skymass/router/apple/foo?foo=xyz";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");

//   u = "http://acme.com/router";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://acme.com/router/";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://acme.com/apple";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://acme.com/apple/foo/";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
//   u = "http://acme.com/apple/foo?foo=xyz";
//   t(u, "peach");
//   t(u, "./peach");
//   t(u, "/peach");
//   t(u, "?foo=bar");
// }

// // tests();

export function handleHistory({
  op,
  path,
}: {
  op: "push" | "replace" | "reset";
  path: string;
}) {
  const normalizedUrl = abs_url_to_route(location.href, path).toString();

  const index = history.state?.index;

  switch (op) {
    case "push":
      history.pushState(null, "", normalizedUrl);
      break;
    case "replace":
      history.replaceState(null, "", normalizedUrl);
      break;
    case "reset":
      // TODO
      break;
  }
  dispatchEvent(
    new CustomEvent(HIST_EVENT, { detail: { url: normalizedUrl } })
  );
}

export type NavigationTarget =
  | string
  | { route: string; params: Record<string, string> };

export function compileNavigationTarget(target: NavigationTarget) {
  if (typeof target === "string") {
    return target;
  }
  return target.route.replace(
    new RegExp(`:(${Object.keys(target.params).join("|")})`, "ig"),
    (_, key) => target.params[key]
  );
}

export function navigateTo(target: NavigationTarget) {
  handleHistory({ op: "push", path: compileNavigationTarget(target) });
}
