import { MonoTypeOperatorFunction } from "rxjs";
import { mapWithState } from "./rx_utils";

export const VALUE_UNCHANGED = Symbol("RECORD_UNCHANGED");
export const KEY_DELETED = Symbol("KEY_DELETED");

type EqualityTest = (a: unknown, b: unknown) => boolean;

// I did not try to type this well...

/**
 * Takes a stream of json-like objects and splits them into patches
 * @param equality
 * @returns
 */
export function breakIntoPatches<T>(
  isEqual?: EqualityTest
): MonoTypeOperatorFunction<T> {
  const buildPatch = makePatcher(isEqual);

  return mapWithState<T, T, T>((prev: any, curr: any) => [
    curr,
    buildPatch(prev, curr),
  ]);
}

export function makePatcher(
  isEqual: EqualityTest = refIsEqual
): (prev: unknown, curr: unknown) => any {
  function buildPatch(prev: any, curr: any) {
    if (isEqual(prev, curr)) {
      return VALUE_UNCHANGED;
    } else if (curr && Array.isArray(curr) && Array.isArray(prev)) {
      // some not particularly advanced array checking. Will only check
      // if values at the same index are equal to each other

      const arrayPatch: unknown[] = [];

      const currLength = curr.length;
      const prevLength = prev.length;
      const shared = Math.min(currLength, prevLength);

      // special case either being empty
      if (shared === 0) {
        return currLength || prevLength ? curr : VALUE_UNCHANGED;
      }

      // compare all the shared values
      let unchangedRunLength = 0;
      for (let i = 0; i < shared; i++) {
        const currVal = curr[i];
        const patch = buildPatch(prev[i], currVal);

        if (patch === VALUE_UNCHANGED) {
          unchangedRunLength++;
        } else {
          // the i === 0 check is to always start the array with VALUE_UNCHANGED
          // to indicate it is a patch which helps with backwards compatibility
          if (unchangedRunLength || i === 0) {
            arrayPatch.push(VALUE_UNCHANGED, unchangedRunLength);
            unchangedRunLength = 0;
          }

          arrayPatch.push(patch);
        }
      }

      if (currLength === prevLength && unchangedRunLength === currLength) {
        // same length and everything was unchanged
        return VALUE_UNCHANGED;
      }

      if (unchangedRunLength) {
        arrayPatch.push(VALUE_UNCHANGED, unchangedRunLength);
      }

      if (currLength < prevLength) {
        // we lost elements
        arrayPatch.push(KEY_DELETED, prevLength - currLength);
      } else if (currLength > prevLength) {
        // we added elements to the end
        for (let i = prevLength; i < currLength; i++) {
          arrayPatch.push(curr[i]);
        }
      }

      return arrayPatch;
    } else if (!isRecord(curr) || !isRecord(prev)) {
      return curr;
    }

    let hasDifference = false;
    let numNewKeys = 0;
    const patch: Record<any, any> = {};

    // write in any changed keys to the patch
    const keys = Object.keys(curr);
    keys.forEach((key) => {
      let val = curr[key];

      if (prev.hasOwnProperty(key)) {
        val = buildPatch(prev[key], val);
      } else {
        numNewKeys++;
      }

      if (val !== VALUE_UNCHANGED) {
        hasDifference = true;
        patch[key] = val;
      }
    });

    const prevKeys = Object.keys(prev);

    // see if any keys were deleted
    if (keys.length - prevKeys.length !== numNewKeys) {
      hasDifference = true;
      prevKeys.forEach((key) => {
        if (!curr.hasOwnProperty(key)) {
          patch[key] = KEY_DELETED;
        }
      });
    }

    return hasDifference ? patch : VALUE_UNCHANGED;
  }

  return buildPatch;
}

export function applyPatches<T>(): MonoTypeOperatorFunction<T> {
  return mapWithState<T, T, T>((prev, patch) => {
    const curr: T = applyPatch(prev, patch);
    return [curr, curr];
  });
}

export function applyPatch(prev: any, patch: any): any {
  if (patch === VALUE_UNCHANGED) {
    return prev;
  } else if (!prev || !patch) {
    return patch;
  } else if (
    Array.isArray(patch) &&
    patch.length &&
    patch[0] === VALUE_UNCHANGED
  ) {
    // patching arrays
    const combined: unknown[] = [];

    for (let i = 0, j = 0; i < patch.length; i++) {
      const item = patch[i];
      if (item === VALUE_UNCHANGED) {
        // the next element is the length of the unchanged run
        const len = patch[++i];
        let k = j;
        j += len;

        while (k < j) combined.push(prev[k++]);
      } else if (item === KEY_DELETED) {
        // the next element is the length of the deleted run
        j += patch[++i];
      } else {
        // item is a patch
        combined.push(applyPatch(prev[j++], patch[i]));
      }
    }

    return combined;
  } else if (!isRecord(prev) || !isRecord(patch)) {
    return patch;
  }

  // we have an object and a patch of that object

  const result: Record<any, any> = {};

  // perform updates
  Object.keys(prev).forEach((key) => {
    const prevVal = prev[key];

    if (!patch.hasOwnProperty(key)) {
      result[key] = prevVal;
    } else {
      const patchVal = patch[key];
      if (patchVal !== KEY_DELETED) {
        result[key] = applyPatch(prevVal, patchVal);
      }
    }
  });

  // add in any new fields
  Object.keys(patch).forEach((key) => {
    if (!prev.hasOwnProperty(key)) {
      result[key] = patch[key];
    }
  });

  return result;
}

function isRecord(a: unknown): boolean {
  const proto = !!a && typeof a === "object" && Object.getPrototypeOf(a);
  return proto === Object.prototype || proto == null;
}

function refIsEqual(a: unknown, b: unknown): boolean {
  return a === b;
}
