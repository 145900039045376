import React, { ReactNode, useState, useRef } from "react";

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
  arrow,
} from "@floating-ui/react";
import { cx } from "./renderValue";

export type ToolTipProps = {
  message?: ReactNode;
  children?: ReactNode;
  className?: string;
  inline?: boolean;
  visible?: boolean;
  style?: React.CSSProperties;
};

export function ToolTip(props: ToolTipProps) {
  const {
    message,
    children,
    className,
    inline = false,
    visible = false,
    style,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const show = !!message || message === 0;

  const arrowRef = useRef(null);

  const { x, y, refs, strategy, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(6),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        className={cx(className, inline ? "tooltip_inline" : null)}
        ref={refs.setReference}
        {...getReferenceProps()}
        style={style ? style : undefined}
      >
        {children}
      </div>
      {show && (visible || isOpen) && (
        <FloatingPortal>
          <div
            className="tooltip"
            ref={refs.setFloating}
            style={{
              // Positioning styles
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              height={5}
              width={10}
            />
            {message}
          </div>
        </FloatingPortal>
      )}
    </>
  );
}
