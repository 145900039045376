import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { Markdown } from "../Markdown";
import {
  InvalidValue,
  INVALID_VALUE,
  MarkdownDeclaration,
  Path,
} from "../protocol";
import { useEvent } from "../server_hooks";

type Props = {
  display?: MarkdownDeclaration;
};

/**
 * Called when the form is submitted, returns whether or not the component is valid.œ
 */
export type OnSubmit = (takeFocusIfInvalid: boolean) => boolean;

export type FormContextType = Readonly<{
  isInForm: boolean;
  elements: Set<OnSubmit>;
  onFormSubmit: (buttonPath: Path, validate: boolean | undefined) => void;
}>;

export const FormContext = createContext<FormContextType>(null);

export function useOnSubmit(onSubmit: OnSubmit) {
  const form = useContext(FormContext);

  useLayoutEffect(() => {
    form.elements.add(onSubmit);
    return () => {
      form.elements.delete(onSubmit);
    };
  }, [form]);
}

export function FormWidget(props: Props): ReactElement {
  return (
    <FormComponent>
      <Markdown display={props.display} />
    </FormComponent>
  );
}

export function FormComponent(props: { children: ReactNode }) {
  const ref = useRef<HTMLFormElement>(null);
  const sendSubmitEvent = useEvent<string | InvalidValue>("submit");

  const context = useMemo<FormContextType>(() => {
    const elements = new Set<OnSubmit>();

    return {
      isInForm: true,
      elements,
      // validate is assumed to be true - to grandfather ui.button in ui.form.action to cause validation
      // otherwise need to use ui.submit which explicitly sets validate = true
      onFormSubmit(path, validate = true) {
        const form = ref.current;
        if (!form) return false; // does not happen

        let valid = true;
        if (validate) {
          elements.forEach((element) => {
            valid = element(valid) && valid;
          });
        }

        if (valid) {
          const buttonId = path[path.length - 1];
          sendSubmitEvent(buttonId, true);
        } else {
          sendSubmitEvent(INVALID_VALUE);
        }
      },
    };
  }, []);

  return (
    <FormContext.Provider value={context}>
      <form noValidate onSubmit={(e) => e.preventDefault()} ref={ref}>
        {props.children}
      </form>
    </FormContext.Provider>
  );
}
