import { RowProps } from "../Row";
import { Copy } from "../Copy";

import "../prism.css";

import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import js from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import ts from "react-syntax-highlighter/dist/esm/languages/prism/typescript";
import sql from "react-syntax-highlighter/dist/esm/languages/prism/sql";
import bash from "react-syntax-highlighter/dist/esm/languages/prism/bash";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import diff from "react-syntax-highlighter/dist/esm/languages/prism/diff";
import { MaybeLabel } from "./MaybeLabel";
import React from "react";

SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("javascript", js);
SyntaxHighlighter.registerLanguage("typescript", ts);
SyntaxHighlighter.registerLanguage("sql", sql);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("diff", diff);

type Props = {
  label?: string;
  language:
    | "jsx"
    | "javascript"
    | "typescript"
    | "sql"
    | "bash"
    | "json"
    | "diff";
  code: string;
  showLines?: boolean;
} & RowProps;

export function CodeWidget({
  label,
  language,
  code,
  rowHasLabel,
  showLines = true,
}: Props) {
  return (
    <label className={showLines ? "show-lines" : undefined}>
      <MaybeLabel label={label} rowHasLabel={rowHasLabel} />
      <SyntaxHighlight code={code} language={language} />
    </label>
  );
}

export function SyntaxHighlight({ code, language }) {
  return (
    <Copy ready>
      <SyntaxHighlighter language={language} wrapLines useInlineStyles={false}>
        {code}
      </SyntaxHighlighter>
    </Copy>
  );
}
