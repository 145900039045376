var p={},t={},v={},w={},x={};
function y(a,b){return function(g){function e(c,d){if(d===p)h.push(c);else if(d===t)c===(c|0)&&h.push(0),h.push(c);else if(d===w)l.push(h.push(c)-1);else if(d===x){d=l.pop();var f=h.length,n=f<<3|h[d];if(n>>3!==f)throw Error("value too large to encode "+String(c));h[d]=n}else if(c===v)r!==p&&(0<=m&&(m=~m),k.set(r,m),r=p);else if(0<=m&&(m=~m,k.set(r,0)),q++,d=k.get(c),null!=d){if(0===d)throw Error("infinite loop when encoding "+c);h.push(d)}else{d=void 0===c?b.s:null===c?b.j:"boolean"===typeof c?b.g:
"number"===typeof c?b.l:"string"===typeof c?b.m:"function"===typeof c?b.i:"symbol"===typeof c?b.o:c instanceof Map?b.Map:c instanceof Set?b.Set:Array.isArray(c)?b.Array:c instanceof ArrayBuffer||c instanceof Int8Array||c instanceof Uint8Array||c instanceof Uint8ClampedArray||c instanceof DataView?b.h:(d=Object.getPrototypeOf(c))&&d!==Object.prototype?b.instance:b.object;f=r;n=m;var u=h.length;r=c;m=q;d(c,e)!==t?k.set(c,0<=m?~m:m):0>m&&k.delete(c);if(u===h.length)throw Error("Value encoded into nothing "+
String(c));r=f;m=n}}var h=[],k=new Map(a),l=[],q=k.size-1,m=0,r=p;e(g,null);return h}}function z(a,b){b(void 0,p);return t}function B(a,b){b(null,p);return t}function D(a,b){b(a,p);return t}function E(a,b){if(a===(a|0))return b(a,t),-129<=a&&128>a&&t;b(a,p);return a!==a&&t}function F(a,b){b(v);b(1,w);for(var g=a.length,e=0;e<g;e++)b(a[e]);b(a,x)}function G(a,b){b(a,p)}function H(a,b){b(v);b(3,w);a.forEach(function(g,e){b(e)});b(a,x);a.forEach(function(g){b(g)})}
function I(a,b){b(v);b(4,w);a.forEach(function(g){b(g)});b(a,x)}function J(a,b){b(v);b(2,w);for(var g=Object.keys(a),e=g.length,h=0;h<e;h++)b(g[h]);b(a,x);for(h=0;h<e;h++)b(a[g[h]])}function K(a,b){b(a,p)}function L(a,b){return a?function(g,e){e(5,w);var h=a(g,e);e(g,x);return h}:b}
function M(a,b,g,e){if(e!==(e|0))return e;if(0>e)return a[~e];switch(e&7){case 0:return g();case 1:var h=[];a.push(h);N(a,h,b,g,e>>3);return p;case 2:h={};a.push(h);var k=[];N(a,k,b,g,e>>3);e=k.length;for(var l=0;l<e;l++)h[k[l]]=O(a,b,g);return p;case 3:h=new Map;a.push(h);k=[];N(a,k,b,g,e>>3);e=k.length;for(l=0;l<e;l++)h.set(k[l],O(a,b,g));return p;case 4:h=new Set;a.push(h);k=[];N(a,k,b,g,e>>3);e=k.length;for(l=0;l<e;l++)h.add(k[l]);return p;case 5:return e=a.push(null)-1,a[e]=b(function(){return O(a,
b,g)}),p;default:throw Error("Rebuilder TODO "+e);}}function O(a,b,g){var e=a.length;b=M(a,b,g,g());if(b===p)return a[e];a.push(b);return b}function N(a,b,g,e,h){for(var k=e(h);k!==x;k=e(h)){var l=a.length;k=M(a,g,e,k);k===p?b.push(a[l]):(a.push(k),b.push(k))}}
function P(a){function b(){var d=a[l++];if(0===d||d!==(d|0)){var f=0===d?a[l++]:d;if(f!==f)e(80);else if(void 0===f)e(16);else if(null===f)e(32);else if("boolean"===typeof f)e(f?48:64);else if("number"===typeof f)if(f===(f|0)){f=0<=f?f<<1:-f<<1|1;var n=127&f<<3|4;if(f>>>=4){for(d=[n|128];f;)n=f&127,f>>>=7,d.push(f?n|128:n);q+=d.length;k.push(new Uint8Array(d))}else e(n)}else n=new Uint8Array(8),(new DataView(n.buffer)).setFloat64(0,f),e(96),q+=8,k.push(n);else if("string"===typeof f)m||(m=new TextEncoder),
g(15,m.encode(f));else if(f instanceof Uint8Array)g(13,f);else if(f instanceof ArrayBuffer)e(112),g(13,new Uint8Array(f));else if(f instanceof Int8Array)e(128),g(13,f);else if(f instanceof Uint8ClampedArray)e(144),g(13,f);else if(f instanceof DataView)e(160),g(13,new Uint8Array(f.buffer,f.byteOffset,f.byteLength));else throw Error("Cannot serialize "+String(f));}else if(0>d){f=~d<<2|2;do n=f&127,f>>>=7,e(f?n|128:n);while(f)}else{var u=d&7;f=d>>3;n=h(u<<1|1);switch(u){case 1:case 4:case 5:for(;l<f;)b();
n();break;case 2:case 3:for(d=0;l<f;)d++,b();n();for(f=d;0<f;f--)b();break;default:throw Error("impossible "+d);}}}function g(d,f){d=h(d);q+=f.byteLength;k.push(f);d()}function e(d){q++;k.push(d)}function h(d){var f=k.push(0)-1,n=q;return function(){var u=q-n,A=127&u<<4|d;if(u>>>=3){for(var C=[A|128];u;)A=u&127,u>>>=7,C.push(u?A|128:A);q+=C.length;k[f]=new Uint8Array(C)}else q++,k[f]=A}}var k=[],l=0,q=0,m=null;b();var r=new Uint8Array(q),c=0;k.forEach(function(d){"number"===typeof d?r[c++]=d:(r.set(d,
c),c+=d.byteLength)});return r};function Q(a){throw Error("No way to atomize "+String(a));}function R(a,b){b(a,t)}function S(a){a=void 0===a?{}:a;var b=a.keepUnknownsAsIs?R:Q;b={s:L(a["void"],z),j:L(a["null"],B),g:L(a["boolean"],D),l:L(a.number,E),Array:L(a.Array,F),m:L(a.string,G),Map:L(a.Map,H),Set:L(a.Set,I),object:L(a.object,J),i:L(a["function"],b),o:L(a.symbol,b),instance:L(a.instance,b),h:L(a.bytes,K)};var g=[],e;null==(e=a.dictionary)||e.forEach(function(h,k){g.push([h,k])});return y(g,b)}export const AS_IS=t;
export const atomizer=S;export const rebuilder=function(a){return function(b){var g=0,e=b.length,h=O([],a,function(k){if(g===k)return x;if(g===e)throw Error("Incomplete data");return b[g++]});if(g!==e)throw Error("rebuilder given excess content");return h}};export const serializeAtoms=P;export const serializer=function(a){var b=S(a);return function(g){return P(b(g))}};
export const deserializer=function(a){var b=null;return function(g){function e(c){if(null!=m)return c=m,m=null,c;if(l===c)return x;if(l===q)throw Error("Incomplete data");c=g[l++];if(c&1)switch(c&15){case 13:c=h(c,4);c=l+c;var d=g.subarray(l,c);l=c;return d;case 15:return c=h(c,4),c=l+c,d=g.subarray(l,c),l=c,b||(b=new TextDecoder),b.decode(d);default:return d=h(c,4),l+d<<3|7&c>>1}else{if(c&2)return~h(c,2);if(c&4)return c=h(c,3),m=c&1?-(c>>>1):c>>>1,0;switch(c){case 16:break;case 32:return null;
case 48:return!0;case 64:return!1;case 80:return NaN;case 96:return k||(k=new DataView(g.buffer,g.byteOffset,g.byteLength)),c=k.getFloat64(l),l+=8,c;case 112:return c=e(),d=c.byteOffset,c.buffer.slice(d,d+c.byteLength);case 128:case 144:case 160:return d=e(),new (128===c?Int8Array:160===c?DataView:Uint8ClampedArray)(d.buffer,d.byteOffset,d.byteLength);default:throw Error("Deserialize given bad byte "+c);}}}function h(c,d){var f=7-d;for(d=(127&c)>>d;c&128;)c=g[l++],d|=(c&127)<<f,f+=7;return d}var k=
null,l=0,q=g.length,m=null,r=O([],a,e);if(l!==q)throw Error("deserializer given excess content");return r}};
